import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ManageQuotation() {
  const [allQuotations, setAllQuotations] = useState([{}, {}]);
  let requestMethods = new RequestMethods();

  const getQuotationList = () => {
    requestMethods.getFunction("/quotation", setAllQuotations);
  };

  function handleDeleteQuotation(id) {
    const api = `/quotation/delete-quotation/${id}`;
    const response = requestMethods.deleteFunction(api, getQuotationList);
    if (response) {
      toast.success("Quotation deleted successfully");
    } else {
      toast.error("Quotation deleting failed!");
    }
  }

  useEffect(() => {
    getQuotationList();
  }, []);

  return (
    <>
      <div className="add__button">
        <Link to="/quotation/add-quotation">
          <button type="button" className="btn">
            <i className="fa-solid fa-plus" />
            Add Quotation
          </button>
        </Link>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>

        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div>
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Date <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Reference <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Customer</th>
              <th scope="col">
                Supplier <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Quotation Status <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Grand Total</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allQuotations.length ? (
              allQuotations?.map((quotation) => (
                <tr>
                  <td>{quotation.date}</td>
                  <td>{quotation.reference}</td>
                  <td>{quotation.customer_id}</td>
                  <td>{quotation.supplier_id}</td>
                  <td>
                    {quotation.status ? (
                      <span className="badge badge-success">Sent</span>
                    ) : (
                      <span className="badge badge-warning">Pending</span>
                    )}
                  </td>
                  <td>{quotation.grand_total}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <Link
                            to={`/quotation/edit-quotation/${quotation.id}`}
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              data-toggle="modal"
                              data-target="#add_unit"
                            >
                              <i className="fa-solid fa-pen-to-square me-2" />
                              Edit
                            </button>
                          </Link>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleDeleteQuotation(quotation.id)}
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  No quotation added
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
