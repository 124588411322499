import React, { useEffect} from "react";

export default function PaymentReport() {
  useEffect(() => {
    document.title = 'Payment Report';  
  }, []);

  return (
    <>
      <h1>Payment Report</h1>
    </>
  );
}