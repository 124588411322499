import React, { useEffect} from "react";

export default function AllPaidSalary() {
  useEffect(() => {
    document.title = 'All Paid Salary';  
  }, []);

  return (
    <>
      <h1>All Paid Salary</h1>
    </>
  );
}