import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const requestMethods = new RequestMethods();
  const navigate = useNavigate();

  const handleEditProduct = (id) => {
    navigate(`/product/update-product/${id}`);
  };
  async function getProducts() {
    requestMethods.getFunction("/product/product-list", setProducts);
  }

  const handleDeleteProduct = async (id) => {
    const productDelApi = `/product/delete-product/${id}`;
    console.log();
    const response = await requestMethods.deleteFunction(
      productDelApi,
      getProducts
    );
    if (response) {
      toast.success("Product deleted successfully!");
    } else {
      toast.error("Product deleting failed!");
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      <div className="add__button">
        <Link to="/product/add-product">
          <button type="button" className="btn">
            <i className="fa-solid fa-plus" />
            Add Product
          </button>
        </Link>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">
                Code <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Brand <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Category <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Unit <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Sell Price <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Supplier</th>
              <th scope="col">Quantity</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!products.length ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  No data available in table
                </td>
              </tr>
            ) : (
              products.map((product) => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>{product.code}</td>
                  <td>{product.brand}</td>
                  <td>{product.category}</td>
                  <td>{product.sell_unit}</td>
                  <td>{product.price}</td>
                  <td>{product.supplier}</td>
                  <td>{product.quantity}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#add_unit"
                            onClick={() => handleEditProduct(product.id)}
                          >
                            <i className="fa-solid fa-pen-to-square me-2" />
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleDeleteProduct(product.id)}
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
