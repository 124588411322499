import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Header() {
  const [heading, setHeading] = useState("");
  const location = useLocation();

  const handleToggleSidebar = () => {
    const container = document.querySelector("#container");
    container.classList.toggle("sidebar-closed");
    container.classList.toggle("sbar-open");
  };

  useEffect(() => {
    //get only the pathname after /
    const path = location.pathname.split("/")[2];

    if (path) {
      //remove the dash and convert the first letter of every word to uppercase
      const heading = path
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      document.title = heading;
      setHeading(heading);

      //close the sidebar in pos
      if (path === "pos") {
        handleToggleSidebar();
      }
    } else {
      setHeading("");
    }
  }, [location.pathname]);

  return (
    <>
      <div className="sub-header-container mb-4">
        <header className="header navbar navbar-expand-sm">
          <span
            className="sidebarCollapse"
            id="sidebarCollapsBtn"
            data-placement="bottom"
            onClick={handleToggleSidebar}
            role="button"
          >
            <i className="las la-bars" />
          </span>
          <div className="heading_text">{heading}</div>
        </header>
      </div>
    </>
  );
}
