import React, { useEffect} from "react";

export default function PurchaseReturnList() {
  useEffect(() => {
    document.title = 'Purchase Return List';  
  }, []);

  return (
    <>
      <h1>Purchase Return List</h1>
    </>
  );
}