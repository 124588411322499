import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function SuppliersList() {
  const [suppliers, setSuppliers] = useState([]);
  const requestMethods = new RequestMethods();

  async function getSuppliers() {
    let api = "supplier";
    requestMethods.getFunction(api, setSuppliers);
  }

  async function handleDelete(id) {
    let api = `/supplier/delete-supplier/${id}`;
    const response = requestMethods.deleteFunction(api, getSuppliers);
    if (response) {
      toast.success("Supplier deleted successfully!");
    } else {
      toast.error("Supplier deleting failed!");
    }
  }

  useEffect(() => {
    getSuppliers();
  }, []);

  return (
    <>
      <div className="add__button">
        <Link to="/suppliers/add-suppliers">
          <button type="button" className="btn">
            <i className="fa-solid fa-plus" />
            Add Supplier
          </button>
        </Link>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">
                Company Name <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                VAT Number <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Email <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Phone Number <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Address <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Total Purchase <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Total Due <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Supplier</th>
              <th scope="col">
                Total Purchase Return <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Status <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!suppliers.length ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  No data available in table
                </td>
              </tr>
            ) : (
              suppliers.map((supplier) => (
                <tr key={supplier.id}>
                  <td>{supplier.name}</td>
                  <td>{supplier.company_name}</td>
                  <td>{supplier.vat_number}</td>
                  <td>{supplier.email}</td>
                  <td>{supplier.phone_number}</td>
                  <td style={{ whiteSpace: "normal" }}>
                    {supplier.address}, {supplier.city}, {supplier.state},{" "}
                    {supplier.postal_code}, {supplier.country}
                  </td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>
                    {supplier.status ? (
                      <span className="badge badge-success btn-xs">Active</span>
                    ) : (
                      <span className="badge badge-danger btn-xs">
                        Inactive
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <Link to={`/suppliers/edit-supplier/${supplier.id}`}>
                            <button
                              className="dropdown-item"
                              type="button"
                              data-toggle="modal"
                              data-target="#add_unit"
                            >
                              <i className="fa-solid fa-pen-to-square me-2" />
                              Edit
                            </button>
                          </Link>
                        </li>
                        <li>
                          <button
                            onClick={() => handleDelete(supplier.id)}
                            className="dropdown-item"
                            type="button"
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
