import { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import HeaderFixed from "./components/HeaderFixed";
import Sidebar from "./components/Sidebar";

import Dashboard from "./Pages/Dashboard";
import ProductList from "./Pages/Product/ProductList";
import AddProduct from "./Pages/Product/AddProduct";
import ProductUnit from "./Pages/Product/ProductUnit";
import ProductCategory from "./Pages/Product/ProductCategory";
import ProductSubcategory from "./Pages/Product/ProductSubcategory";
import ProductChildCategory from "./Pages/Product/ProductChildCategory";
import ProductSize from "./Pages/Product/ProductSize";
import ProductColor from "./Pages/Product/ProductColor";
import ProductBrand from "./Pages/Product/ProductBrand";
import PrintBarcode from "./Pages/Product/PrintBarcode";
import CustomerReceivable from "./Pages/Quicklinks/CustomerReceivable";
import SuppliersPayable from "./Pages/Quicklinks/SuppliersPayable";
import TodaysSummery from "./Pages/Quicklinks/TodaysSummery";
import ReturnOrder from "./Pages/Quicklinks/ReturnOrder";
// import Pos from "./Pages/Quicklinks/Pos";
import AddSuppliers from "./Pages/Suppliers/AddSuppliers";
import SuppliersList from "./Pages/Suppliers/SuppliersList";
import CustomerList from "./Pages/Customer/CustomerList";
import AddCustomer from "./Pages/Customer/AddCustomer";
import DueRecievedList from "./Pages/Customer/DueRecievedList";
import BusinessDuePayList from "./Pages/Customer/BusinessDuePayList";
import CustomerGroup from "./Pages/Customer/CustomerGroup";
import AddQuotation from "./Pages/Quotation/AddQuotation";
import ManageQuotation from "./Pages/Quotation/ManageQuotation";
import AddPurchase from "./Pages/Purchase/AddPurchase";
import PurchaseList from "./Pages/Purchase/PurchaseList";
import PurchaseReturnType from "./Pages/Purchase/PurchaseReturnType";
import PurchaseReturnList from "./Pages/Purchase/PurchaseReturnList";
import CurrentStock from "./Pages/Stockmanagement/CurrentStock";
import Transfer from "./Pages/Stockmanagement/Transfer";
import Adjustment from "./Pages/Stockmanagement/Adjustment";
import TransferredList from "./Pages/Stockmanagement/TransferredList";
import ReceivedList from "./Pages/Stockmanagement/ReceivedList";
import AddTransfer from "./Pages/Transfer/AddTransfer";
import TransferList from "./Pages/Transfer/TransferList";
import Pos from "./Pages/Sales/Pos";
import ManageSales from "./Pages/Sales/ManageSales";
import AddReturn from "./Pages/Sales/AddReturn";
import SalesReturnList from "./Pages/Sales/SalesReturnList";
import GiftCardList from "./Pages/Sales/GiftCardList";
import CouponList from "./Pages/Sales/CouponList";
import ExpenseCategory from "./Pages/Expense/ExpenseCategory";
import AssetList from "./Pages/Asset/AssetList";
import AssetCategory from "./Pages/Asset/AssetCategory";
import AddEmployee from "./Pages/Employee/AddEmployee";
import EmployeeList from "./Pages/Employee/EmployeeList";
import EmployeeCategory from "./Pages/Employee/EmployeeCategory";
import AllPaidSalary from "./Pages/Employee/AllPaidSalary";
import AdvanceSalary from "./Pages/Employee/AdvanceSalary";
import DueSalary from "./Pages/Employee/DueSalary";
import Attendance from "./Pages/Employee/Attendance";
import PayRoll from "./Pages/Employee/PayRoll";
import Department from "./Pages/Employee/Department";
import AllUser from "./Pages/User/AllUser";
import CreateUser from "./Pages/User/CreateUser";
import UserRole from "./Pages/User/UserRole";
import ReceiveReport from "./Pages/Accounts/ReceiveReport";
import ReceivedReport from "./Pages/Accounts/ReceivedReport";
import ReceivableReport from "./Pages/Accounts/ReceivableReport";
import PaymentReport from "./Pages/Accounts/PaymentReport";
import PaidReport from "./Pages/Accounts/PaidReport";
import PayableReport from "./Pages/Accounts/PayableReport";
import SalaryReport from "./Pages/Accounts/SalaryReport";
import ExpenseReport from "./Pages/Accounts/ExpenseReport";
import AccountType from "./Pages/Accounts/AccountType";
import AccountTypeList from "./Pages/Accounts/AccountTypeList";
import ProfitLoss from "./Pages/Report/ProfitLoss";
import ProductReport from "./Pages/Report/ProductReport";
import CategoryReport from "./Pages/Report/CategoryReport";
import SalesReport from "./Pages/Report/SalesReport";
import DailySalesReport from "./Pages/Report/DailySalesReport";
import MonthlySalesReport from "./Pages/Report/MonthlySalesReport";
import PurchaseReport from "./Pages/Report/PurchaseReport";
import CustomerReport from "./Pages/Report/CustomerReport";
import SupplierReport from "./Pages/Report/SupplierReport";
import SoldProductReport from "./Pages/Report/SoldProductReport";
import WarehouseReport from "./Pages/Report/WarehouseReport";
import WarehouseStockChart from "./Pages/Report/WarehouseStockChart";
import BusinessSetting from "./Pages/Business/BusinessSetting";
import BusinessBranches from "./Pages/Business/BusinessBranches";
import NoticeSend from "./Pages/Business/NoticeSend";
import VatTax from "./Pages/Business/VatTax";
import NotFound from "./Pages/NotFound";
import EditSuppliers from "./Pages/Suppliers/EditSuppliers";
import { ToastContainer } from "react-toastify";
import EditCustomer from "./Pages/Customer/EditCustomer";
import Header from "./components/Header";
import ProductUpdate from "./Pages/Product/ProductUpdate";
import ManageWarehouse from "./Pages/Warehouse/ManageWarehouse";
import ManageExpense from "./Pages/Expense/ManageExpense";
import UpdateQuotation from "./Pages/Quotation/UpdateQuotation";
import UpdatePurchase from "./Pages/Purchase/UpdatePurchase";
import UpdateTransfer from "./Pages/Transfer/UpdateTransfer";
import UpdateEmployee from "./Pages/Employee/UpdateEmployee";
import AddSales from "./Pages/Sales/AddSales";
import UpdateSales from "./Pages/Sales/UpdateSales";
import UpdateReturn from "./Pages/Sales/UpdateReturn";
import Accounts from "./Pages/Accounts/Accounts";
import PurchaseInvoice from "./Pages/Purchase/PurchaseInvoice";
import SalesInvoice from "./Pages/Sales/SalesInvoice";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <ProtectedRoute>
      <HeaderFixed />
      <ToastContainer />
      <div className="main-container" id="container">
        <div className="sidebar-wrapper sidebar-theme">
          <Sidebar />
        </div>
        {/*  Content Area Starts  */}
        <div id="content" className="main-content px-3">
          <Header />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/product">
              <Route path="product-list" element={<ProductList />} />
              <Route path="add-product" element={<AddProduct />} />
              <Route path="product-unit" element={<ProductUnit />} />
              <Route path="product-category" element={<ProductCategory />} />
              <Route path="sub-category" element={<ProductSubcategory />} />
              <Route path="child-category" element={<ProductChildCategory />} />
              <Route path="product-size" element={<ProductSize />} />
              <Route path="product-color" element={<ProductColor />} />
              <Route path="product-brand" element={<ProductBrand />} />
              <Route path="print-barcode" element={<PrintBarcode />} />
              <Route path="update-product/:id" element={<ProductUpdate />} />
            </Route>
            <Route path="/warehouse">
              <Route path="manage-warehouse" element={<ManageWarehouse />} />
            </Route>
            <Route path="/quicklinks">
              <Route
                path="customer-receivable"
                element={<CustomerReceivable />}
              />
              <Route path="suppliers-payable" element={<SuppliersPayable />} />
              <Route path="todays-summery" element={<TodaysSummery />} />
              <Route path="return-order" element={<ReturnOrder />} />
              <Route path="pos" element={<Pos />} />
            </Route>
            <Route path="/suppliers">
              <Route path="add-suppliers" element={<AddSuppliers />} />
              <Route path="suppliers-list" element={<SuppliersList />} />
              <Route path="edit-supplier/:id" element={<EditSuppliers />} />
            </Route>
            <Route path="/customer">
              <Route path="customer-list" element={<CustomerList />} />
              <Route path="add-customer" element={<AddCustomer />} />
              <Route path="edit-customer/:id" element={<EditCustomer />} />
              <Route path="due-recieved-list" element={<DueRecievedList />} />
              <Route
                path="business-due-pay-list"
                element={<BusinessDuePayList />}
              />
              <Route path="customer-group" element={<CustomerGroup />} />
            </Route>
            <Route path="/quotation">
              <Route path="add-quotation" element={<AddQuotation />} />
              <Route path="edit-quotation/:id" element={<UpdateQuotation />} />
              <Route path="manage-quotation" element={<ManageQuotation />} />
            </Route>
            <Route path="/purchase">
              <Route path="add-purchase" element={<AddPurchase />} />
              <Route path="manage-purchase" element={<PurchaseList />} />
              <Route path="purchase-invoice" element={<PurchaseInvoice />} />
              <Route path="edit-purchase/:id" element={<UpdatePurchase />} />
              <Route
                path="purchase-return-type"
                element={<PurchaseReturnType />}
              />
              <Route
                path="purchase-return-list"
                element={<PurchaseReturnList />}
              />
            </Route>
            <Route path="/stockmanagement">
              <Route path="current-stock" element={<CurrentStock />} />
              <Route path="transfer" element={<Transfer />} />
              <Route path="adjustment" element={<Adjustment />} />
              <Route path="transferred-list" element={<TransferredList />} />
              <Route path="received-list" element={<ReceivedList />} />
            </Route>
            <Route path="/transfer">
              <Route path="add-transfer" element={<AddTransfer />} />
              <Route path="transfer-list" element={<TransferList />} />
              <Route path="update-transfer/:id" element={<UpdateTransfer />} />
            </Route>
            <Route path="/sales">
              <Route path="pos" element={<Pos />} />
              <Route path="manage-sales" element={<ManageSales />} />
              <Route path="add-sales" element={<AddSales />} />
              <Route path="sales-invoice" element={<SalesInvoice />} />
              <Route path="edit-sales/:id" element={<UpdateSales />} />
              <Route path="add-return/:id" element={<AddReturn />} />
              <Route path="edit-return/:id" element={<UpdateReturn />} />
              <Route path="return" element={<SalesReturnList />} />
              <Route path="gift-card-list" element={<GiftCardList />} />
              <Route path="coupon-list" element={<CouponList />} />
            </Route>
            <Route path="/expense">
              <Route path="manage-expense" element={<ManageExpense />} />
              <Route path="expense-category" element={<ExpenseCategory />} />
            </Route>
            <Route path="/asset">
              <Route path="asset-list" element={<AssetList />} />
              <Route path="asset-category" element={<AssetCategory />} />
            </Route>
            <Route path="/employee">
              <Route path="add-employee" element={<AddEmployee />} />
              <Route path="employee-list" element={<EmployeeList />} />
              <Route path="edit-employee/:id" element={<UpdateEmployee />} />
              <Route path="employee-category" element={<EmployeeCategory />} />
              <Route path="all-paid-salary" element={<AllPaidSalary />} />
              <Route path="advance-salary" element={<AdvanceSalary />} />
              <Route path="due-salary" element={<DueSalary />} />
              <Route path="attendance" element={<Attendance />} />
              <Route path="pay-roll" element={<PayRoll />} />
              <Route path="department" element={<Department />} />
            </Route>
            <Route path="/user">
              <Route path="all-user" element={<AllUser />} />
              <Route path="create-user" element={<CreateUser />} />
              <Route path="user-role" element={<UserRole />} />
              <Route path="customer-list" element={<CustomerList />} />
            </Route>
            <Route path="/accounts">
              <Route path="manage-accounts" element={<Accounts />} />
              <Route path="receive-report" element={<ReceiveReport />} />
              <Route path="received-report" element={<ReceivedReport />} />
              <Route path="receivable-report" element={<ReceivableReport />} />
              <Route path="payment-report" element={<PaymentReport />} />
              <Route path="paid-report" element={<PaidReport />} />
              <Route path="payable-report" element={<PayableReport />} />
              <Route path="salary-report" element={<SalaryReport />} />
              <Route path="expense-report" element={<ExpenseReport />} />
              <Route path="account-type" element={<AccountType />} />
              <Route path="account-type-list" element={<AccountTypeList />} />
            </Route>
            <Route path="/report">
              <Route path="profit-loss" element={<ProfitLoss />} />
              <Route path="product-report" element={<ProductReport />} />
              <Route path="category-report" element={<CategoryReport />} />
              <Route path="sales-report" element={<SalesReport />} />
              <Route path="daily-sales-report" element={<DailySalesReport />} />
              <Route
                path="monthly-sales-report"
                element={<MonthlySalesReport />}
              />
              <Route path="purchase-report" element={<PurchaseReport />} />
              <Route path="customer-report" element={<CustomerReport />} />
              <Route path="supplier-report" element={<SupplierReport />} />
              <Route
                path="sold-product-report"
                element={<SoldProductReport />}
              />
              <Route path="warehouse-report" element={<WarehouseReport />} />
              <Route
                path="warehouse-stock-chart"
                element={<WarehouseStockChart />}
              />
            </Route>
            <Route path="/business">
              <Route path="business-setting" element={<BusinessSetting />} />
              <Route path="business-branches" element={<BusinessBranches />} />
              <Route path="notice-send" element={<NoticeSend />} />
              <Route path="vat-tax" element={<VatTax />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        {/*  Content Area Ends  */}
      </div>
    </ProtectedRoute>
  );
}

export default App;
