import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function CustomerList() {
  const [customers, setCustomers] = useState([]);
  const requestMethods = new RequestMethods();

  async function getCustomers() {
    let api = "customer";
    requestMethods.getFunction(api, setCustomers);
  }

  async function handleDelete(id) {
    let api = `/customer/delete-customer/${id}`;
    const response = requestMethods.deleteFunction(api, getCustomers);
    if (response) {
      toast.success("Customer deleted successfully!");
    } else {
      toast.error("Customer deleting failed!");
    }
  }

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <>
      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Customer Group <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Name <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Company Name <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Email <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Phone Number <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Tax Number
                <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Address <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Balance <i className="fa-solid fa-sort" />
              </th>

              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.groupType}</td>
                <td>{customer.name}</td>
                <td>{customer.company}</td>
                <td>{customer.email}</td>
                <td>{customer.phone_number}</td>
                <td>{customer.tax_number}</td>
                <td>{customer.address}</td>
                <td>{customer.group}</td>
                <td>
                  <div className="dropdown dropstart">
                    <button
                      className="btn btn-warning dropdown-toggle btn-sm"
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenu2"
                    >
                      <li>
                        <Link to={`/customer/edit-customer/${customer.id}`}>
                          <button
                            // onClick={}
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#add_unit"
                          >
                            <i className="fa-solid fa-pen-to-square me-2" />
                            Edit
                          </button>
                        </Link>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => handleDelete(customer.id)}
                        >
                          <i className="fa-solid fa-trash me-2" />
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
