import React, { useEffect} from "react";

export default function PurchaseReturnType() {
  useEffect(() => {
    document.title = 'Purchase Return Type';  
  }, []);

  return (
    <>
      <h1>Purchase Return Type</h1>
    </>
  );
}