import { toast } from "react-toastify";
import request from "./axios";

export default class RequestMethods {
  async getFunction(api, setState) {
    try {
      let requestedData = await request.get(api);
      setState(requestedData.data);
    } catch (error) {
      console.error(error);
    }
  }

  async postFunction(api, formData) {
    try {
      const postRequest = await request.post(api, formData, {
        "Content-Type": "multipart/form-data",
      });

      return postRequest.data;
    } catch (err) {
      toast.error(err);
    }
  }

  async patchFunction(api, formData) {
    try {
      const patchRequest = await request.patch(api, formData, {
        "Content-Type": "multipart/form-data",
      });

      return patchRequest.data;
    } catch (error) {
      return false;
    }
  }

  async deleteFunction(api, getFunction) {
    let deleteRequest = await request.delete(api);
    if (deleteRequest.data.success) {
      getFunction();
      return true;
    } else {
      return false;
    }
  }
}

export function setCheckBoxToFormData(e, formData, field) {
  if (e.target[field].checked) {
    formData.set(field, 1);
  } else {
    formData.set(field, 0);
  }
}

export function checkNumber(num) {
  if (isNaN(num) || num < 0) {
    return 0;
  }
  return num;
}
