import { useState, useLayoutEffect } from "react";

const SaleOrderedProductRow = ({
  product,
  handleOrderProductDelete,
  setOrderProducts,
  orderProducts,
}) => {
  const [orderProductQuantity, setOrderProductQuantity] = useState(1);

  const updateProductMetaObject = () => {
    //find the specific product meta data with id and update the value
    const productQuantity = isNaN(orderProductQuantity)
      ? 0
      : orderProductQuantity;
    const updatedProductMetaData = orderProducts.map((data) => {
      if (data.id === product.id) {
        return {
          ...data,
          productQuantity: productQuantity,
        };
      } else {
        return data;
      }
    });

    setOrderProducts(updatedProductMetaData);
  };

  function productQtyChangeHandler(qty) {
    if (qty > product.quantity) {
      alert("You exceeded product quantity!");
    } else {
      setOrderProductQuantity(qty);
    }
  }

  useLayoutEffect(() => {
    updateProductMetaObject();
  }, [orderProductQuantity]);

  return (
    <tr key={product.id}>
      <td>{product.name}</td>
      <td>{product.code}</td>
      <td>
        <input
          className="w-100 form-control"
          type="number"
          name="quantity"
          value={orderProductQuantity}
          onChange={(e) => productQtyChangeHandler(e.target.valueAsNumber)}
          min={1}
          id="quantity"
        />
      </td>
      <td>{product.price}</td>
      <td>0</td>
      <td>0</td>
      <td>
        {isNaN(orderProductQuantity) ? 0 : orderProductQuantity * product.price}
      </td>
      <td>{product.quantity}</td>
      <td>N/A</td>
      <td>N/A</td>
      <td>N/A</td>
      <td onClick={() => handleOrderProductDelete(product.id)}>
        <button className="btn btn-danger">Delete</button>
      </td>
    </tr>
  );
};

export default SaleOrderedProductRow;
