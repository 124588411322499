import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import request from "../axios";
import Auth from "../Pages/Auth/Auth";

export default function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("user");

  async function validateUserId() {
    let response = await request.get(`/auth/validate-user/${isLoggedIn}`);
    if (!response.data.success) {
      localStorage.removeItem("user");
      navigate("/login");
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      validateUserId();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [location.pathname]);

  if (!isLoggedIn) {
    return <Auth />;
  }
  return <>{children}</>;
}
