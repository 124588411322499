import { toast } from "react-toastify";
import RequestMethods from "../../helper";
import { useNavigate } from "react-router-dom";

export default function AddCustomer() {
  const navigate = useNavigate();
  const requestMethods = new RequestMethods();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const addCustomerForm = document.getElementById("addCustomerForm");
    let formData = new FormData(addCustomerForm);

    const api = "/customer/add-customer";
    const response = await requestMethods.postFunction(api, formData);
    if (response.success) {
      addCustomerForm.reset();
      navigate("/customer/customer-list");
      toast.success("Customer successfully added!");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  return (
    <>
      <form className="my-5" id="addCustomerForm" onSubmit={handleSubmit}>
        <div className="row gy-3">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="customerType" className="form-label mb-2">
                Customer Group
              </label>
              <select
                className="custom-select"
                id="customerType"
                name="group"
                aria-label="Default select example"
              >
                <option value="general" selected>
                  General
                </option>
                <option value="distributor">Distributor</option>
                <option value="reseller">Reseller</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="customerName" className="form-label mb-2">
                Customer Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="customerName"
                name="name"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="companyName" className="form-label mb-2">
                Company Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="email" className="form-label mb-2">
                Email *
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="phoneNumber" className="form-label mb-2">
                Phone Number *
              </label>
              <input
                type="tel"
                pattern="[0-9]{11}"
                className="form-control"
                placeholder="example: 01633564509"
                id="phoneNumber"
                name="phoneNumber"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="tax" className="form-label mb-2">
                Tax Number
              </label>
              <input type="tel" className="form-control" id="tax" name="tax" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="address" className="form-label mb-2">
                Address *
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="city" className="form-label mb-2">
                City *
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="state" className="form-label mb-2">
                State *
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                name="state"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="postalCode" className="form-label mb-2">
                Postal Code
              </label>
              <input
                type="number"
                className="form-control"
                id="postalCode"
                name="postalCode"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="country" className="form-label mb-2">
                Country *
              </label>
              <input
                type="text"
                className="form-control"
                id="country"
                name="country"
              />
            </div>
          </div>
        </div>
        <button className="btn btn-success mt-3" type="submit">
          Add Customer
        </button>
      </form>
    </>
  );
}
