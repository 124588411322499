import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ProductBrand() {
  const [brands, setBrands] = useState([]);
  const [editBrand, seteditBrand] = useState([]);
  const [image, setImage] = useState(null);
  const requestMethods = new RequestMethods();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let form = document.getElementById("productBrandForm");
    let closeModal = document.querySelector("#brandCloseModal");
    let formData = new FormData(form);
    formData.append("image", image);

    if (editBrand.length) {
      const api = `/product/edit-brand/${editBrand[0].id}`;
      const response = await requestMethods.patchFunction(api, formData);
      if (response.success) {
        form.reset();
        toast.success("Brand updated successfully!");
        closeModal.click();
        getProductBrands();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0].field].focus();
      }
    } else {
      const api = "/product/add-brand";
      const response = await requestMethods.postFunction(api, formData);
      if (response.success) {
        form.reset();
        toast.success("Brand added successfully");
        closeModal.click();
        getProductBrands();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0].field].focus();
      }
    }
  };

  const handleDelete = (id) => {
    const api = `/product/delete-brand/${id}`;
    const response = requestMethods.deleteFunction(api, getProductBrands);
    if (response) {
      toast.success("Brand deleted successfully!");
    }
  };

  const getProductBrands = () => {
    const api = "/product/brand";
    requestMethods.getFunction(api, setBrands);
  };

  const getSingleProductBrand = (id) => {
    const api = `/product/brand/${id}`;
    requestMethods.getFunction(api, seteditBrand);
  };

  const setInit = () => {
    seteditBrand([]);
    setImage(null);
  };

  useEffect(() => {
    getProductBrands();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#brand_modal"
          onClick={setInit}
        >
          <i className="fa-solid fa-plus" />
          Add Brand
        </button>
      </div>
      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">
                Brand <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!brands.length ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  No data available in table
                </td>
              </tr>
            ) : (
              brands?.map((brand) => (
                <tr key={brand.id}>
                  <td>
                    <img
                      style={{ width: "50px" }}
                      src={`${process.env.REACT_APP_BASE_URL}${brand.image}`}
                      alt=""
                    />
                  </td>
                  <td>{brand.title}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#brand_modal"
                            onClick={() => getSingleProductBrand(brand.id)}
                          >
                            <i className="fa-solid fa-pen-to-square me-2" />
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => handleDelete(brand.id)}
                            className="dropdown-item"
                            type="button"
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="brand_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {editBrand.length ? "Edit Brand" : "Add Brand"}
              </h5>
              <button
                type="button"
                className="close"
                id="brandCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} id="productBrandForm">
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    id="title"
                    defaultValue={editBrand[0]?.title}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="formFile" className="form-label">
                    Image
                  </label>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      name="image"
                      onChange={(e) => setImage(e.target.files[0])}
                      aria-describedby="image"
                    />
                  </div>
                </div>
                <button className="btn btn-success" type="submit">
                  {editBrand.length ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
