import React, { useEffect} from "react";

export default function CustomerGroup() {
  useEffect(() => {
    document.title = 'Customer Group';  
  }, []);

  return (
    <>
      <h1>Customer Group</h1>
    </>
  );
}