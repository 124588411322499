import React, { useEffect} from "react";

export default function NoticeSend() {
  useEffect(() => {
    document.title = 'Notice Send';  
  }, []);

  return (
    <>
      <h1>Notice Send</h1>
    </>
  );
}