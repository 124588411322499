import React, { useEffect} from "react";

export default function ProfitLoss() {
  useEffect(() => {
    document.title = 'Profit Loss';  
  }, []);

  return (
    <>
      <h1>Profit Loss</h1>
    </>
  );
}