import { useState } from "react";
import {
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Chart as ChartJS,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./style.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const yearlabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weeklabels = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const dataFake = [
  { name: "Alice", age: 25, income: 45000 },
  { name: "Bob", age: 32, income: 50000 },
  { name: "Charlie", age: 40, income: 75000 },
  { name: "Diane", age: 28, income: 60000 },
  { name: "Eve", age: 35, income: 55000 },
];
const dataFake2 = [
  { name: "Alice", age: 25, income: 35000 },
  { name: "Bob", age: 32, income: 60000 },
  { name: "Charlie", age: 40, income: 45000 },
  { name: "Diane", age: 28, income: 50000 },
  { name: "Eve", age: 35, income: 55000 },
];

export default function BarChart() {
  const [isWeek, setIsWeek] = useState(1);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Purchase & Sales ${isWeek ? "weekly" : "yearly"} Report`,
      },
    },
  };
  const data = {
    labels: isWeek ? weeklabels : yearlabels,
    datasets: [
      {
        label: "Purchase",
        data: dataFake.map((data) => data.income),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Sales",
        data: dataFake2.map((data) => data.income),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div className="bar_chart_container">
      <div className="d-flex">
        <div
          class="btn-group chart_duration_btn ml-auto"
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            class={`btn btn-secondary ${isWeek && "active"}`}
            onClick={() => setIsWeek(1)}
          >
            Weekly
          </button>
          <button
            type="button"
            class={`btn btn-secondary ${isWeek || "active"}`}
            onClick={() => setIsWeek(0)}
          >
            Yearly
          </button>
        </div>
      </div>
      <Bar options={options} data={data} />
    </div>
  );
}
