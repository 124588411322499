import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function UpdatePurchase() {
  let requestMethods = new RequestMethods();
  const [editPurchase, setEditPurchase] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [allWarehouse, setAllWarehouse] = useState([]);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleFormUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("file", file);

    const purchasePostApi = `/purchase/edit-purchase/${id}`;
    const response = await requestMethods.patchFunction(
      purchasePostApi,
      formData
    );
    if (response.success) {
      e.target.reset();
      setFile();
      navigate("/purchase/manage-purchase");
      toast.success("Purhcase added successfully!");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    let singlePurchasApi = `/purchase/${id}`;
    requestMethods.getFunction(singlePurchasApi, setEditPurchase);

    let supplierAPi = "/supplier";
    requestMethods.getFunction(supplierAPi, setAllSuppliers);

    let warehouseAPi = "/warehouse";
    requestMethods.getFunction(warehouseAPi, setAllWarehouse);
  }, []);

  return (
    <div className="p-4">
      <h4 className="mb-3 mt-3">Update Purchase</h4>
      <div>
        <p className="mb-4 mt-3 italic">
          <small>
            The field labels marked with * are required input fields.
          </small>
        </p>
        <form id="purchase-form" onSubmit={handleFormUpdate}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="Warehouse" className="form-label mb-2">
                  Warehouse *
                </label>
                <select
                  className="custom-select"
                  id="Warehouse"
                  name="warehouse"
                  required
                >
                  <option value="N/A">Select warehouse...</option>
                  {allWarehouse?.map((warehouse) => (
                    <option
                      selected={
                        editPurchase[0]?.warehouse_id == warehouse.id
                          ? "selected"
                          : ""
                      }
                      value={warehouse.id}
                    >
                      {warehouse.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="Supplier" className="form-label mb-2">
                  Supplier *
                </label>
                <select
                  className="custom-select"
                  id="Supplier"
                  name="supplier"
                  required
                >
                  <option value="N/A">Select supplier...</option>
                  {allSuppliers.map((supplier) => (
                    <option
                      selected={
                        editPurchase[0]?.supplier_id == supplier.id
                          ? "selected"
                          : ""
                      }
                      key={supplier.id}
                      value={supplier.id}
                    >
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="purchasestatus" className="form-label mb-2">
                  Purchase Status
                </label>
                <select
                  className="custom-select"
                  id="purchasestatus"
                  name="purchasestatus"
                  required
                >
                  <option
                    value="recieved"
                    selected={
                      editPurchase[0]?.purchase_status == "recieved" &&
                      "selected"
                    }
                  >
                    Recieved
                  </option>
                  <option
                    value="partial"
                    selected={
                      editPurchase[0]?.purchase_status == "partial" &&
                      "selected"
                    }
                  >
                    Partial
                  </option>
                  <option
                    value="pending"
                    selected={
                      editPurchase[0]?.purchase_status == "pending" &&
                      "selected"
                    }
                  >
                    Pending
                  </option>
                  <option
                    value="ordered"
                    selected={
                      editPurchase[0]?.purchase_status == "ordered" &&
                      "selected"
                    }
                  >
                    Ordered
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="file">
                  Attach Document{" "}
                  <i
                    className="fa-regular fa-circle-question"
                    data-toggle="tooltip"
                    title="Only png, jpeg, pdf, txt file is supported!"
                  ></i>
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="form-control"
                  onChange={(e) => setFile(e.target.files[0])}
                  accept="image/png, image/jpeg, application/pdf, application/txt"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="supplierInvoice" className="form-label mb-2">
                  Supplier Invoice
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplierInvoice"
                  name="supplierInvoice"
                  defaultValue={editPurchase[0]?.supplier_invoice}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="mb-2">
                <label htmlFor="note" className="form-label mb-2">
                  Note
                </label>
                <textarea
                  className="form-control"
                  id="note"
                  name="note"
                  rows="4"
                  cols="50"
                  defaultValue={editPurchase[0]?.note || ""}
                ></textarea>
              </div>
            </div>
          </div>

          <button className="btn btn-success mt-3" type="submit">
            Update
          </button>
        </form>
      </div>
    </div>
  );
}
