import React, { useEffect} from "react";

export default function MonthlySalesReport() {
  useEffect(() => {
    document.title = 'Monthly Sales Report';  
  }, []);

  return (
    <>
      <h1>Monthly Sales Report</h1>
    </>
  );
}