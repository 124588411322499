import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ManageExpense() {
  const [allExpense, setAllExpense] = useState([]);
  const [editExpense, setEditExpense] = useState([]);
  const [allExpenseCategory, setAllExpenseCategory] = useState([]);
  const [allWarehouse, setAllWarehouse] = useState([]);
  const [accounts, setAccounts] = useState([]);
  let requestMethods = new RequestMethods();

  const getExpenseList = () => {
    requestMethods.getFunction("/expense", setAllExpense);
  };

  function getSingleExpenseList(id) {
    requestMethods.getFunction(`/expense/${id}`, setEditExpense);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("date", new Date().toLocaleString());
    formData.append(
      "reference",
      `qr-${Math.floor(Math.random() * 10000000000)}`
    );

    if (editExpense.length) {
      const api = `/expense/edit-expense/${editExpense[0].id}`;
      const response = await requestMethods.patchFunction(api, formData);

      if (response.success) {
        e.target.reset();
        toast.success("Expense updated successfully");
        document.querySelector("#expenseCloseModal").click();
        getExpenseList();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0]?.field].focus();
      }
    } else {
      const api = "/expense/add-expense";
      const response = await requestMethods.postFunction(api, formData);

      if (response.success) {
        toast.success("Expense added successfully");
        getExpenseList();
        document.querySelector("#expenseCloseModal").click();
        e.target.reset();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0]?.field].focus();
      }
    }
  };

  function handleDeleteExpense(id) {
    const response = requestMethods.deleteFunction(
      `/expense/delete-expense/${id}`,
      getExpenseList
    );
    if (response) {
      toast.success("Expense deleted successfully");
    } else {
      toast.error("Expense deleting failed!");
    }
  }

  function getInitialData() {
    const getExCategoryApi = "/expense/category";
    requestMethods.getFunction(getExCategoryApi, setAllExpenseCategory);
    requestMethods.getFunction("/warehouse", setAllWarehouse);
    requestMethods.getFunction("/account", setAccounts);
  }

  useEffect(() => {
    getExpenseList();
    getInitialData();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#expense_modal"
          onClick={() => setEditExpense([])}
        >
          <i className="fa-solid fa-plus" />
          Add Expense
        </button>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>

        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div>
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Date <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Reference <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Warehouse <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Category <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Amount <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Note <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allExpense.length ? (
              allExpense?.map((expense) => (
                <tr>
                  <td>{expense.date}</td>
                  <td>{expense.reference}</td>
                  <td>{expense.warehouse}</td>
                  <td>{expense.expenseCategory}</td>
                  <td>{expense.amount}</td>
                  <td>{expense.note}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#expense_modal"
                            onClick={() => getSingleExpenseList(expense.id)}
                          >
                            <i className="fa-solid fa-pen-to-square me-2" />
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleDeleteExpense(expense.id)}
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No data added
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="expense_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="expense_modal">
                {editExpense.length ? "Edit Expense" : "Add Expense"}
              </h5>
              <button
                type="button"
                className="close"
                id="expenseCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p class="italic">
                <small>
                  The field labels marked with * are required input fields.
                </small>
              </p>
              <form onSubmit={handleSubmit} id="ExpenseForm">
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="expense_category" className="form-label">
                      Expense Category *
                    </label>
                    <select
                      className="custom-select"
                      id="expense_category"
                      name="expense_category"
                      aria-label="Default select example"
                    >
                      <option value="">Select expense category</option>
                      {allExpenseCategory?.map((expense_cat) => (
                        <option
                          selected={
                            editExpense[0]?.expenseCategory == expense_cat.id
                              ? "selected"
                              : ""
                          }
                          value={expense_cat.id}
                          key={expense_cat.id}
                        >
                          {expense_cat.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="warehouse" className="form-label">
                      Warehouse *
                    </label>
                    <select
                      className="custom-select"
                      id="warehouse"
                      name="warehouse"
                      aria-label="Default select example"
                    >
                      <option value="">Select warehouse</option>
                      {allWarehouse?.map((warehouse) => (
                        <option
                          selected={
                            editExpense[0]?.warehouse == warehouse.id
                              ? "selected"
                              : ""
                          }
                          value={warehouse.id}
                          key={warehouse.id}
                        >
                          {warehouse.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="amount" className="form-label mb-2">
                      Amount *
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="amount"
                      name="amount"
                      defaultValue={editExpense[0]?.amount}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="account" className="form-label">
                      Account
                    </label>
                    <select
                      className="custom-select"
                      id="account"
                      name="account"
                      aria-label="Default select example"
                    >
                      <option value="">Select account</option>
                      {accounts?.map((account) => (
                        <option
                          selected={
                            editExpense[0]?.account == account.id
                              ? "selected"
                              : ""
                          }
                          value={account.id}
                          key={account.id}
                        >
                          {account.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-md-12">
                    <div class="form-group">
                      <label htmlFor="note" className="form-label">
                        Note
                      </label>
                      <textarea
                        name="note"
                        id="note"
                        rows="3"
                        class="form-control"
                        defaultValue={editExpense[0]?.note || ""}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button className="btn btn-success" type="submit">
                  {editExpense.length ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
