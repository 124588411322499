import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";
import OrderedProductRow from "./OrderedProductRow";

export default function AddPurchase() {
  const navigage = useNavigate();
  const [selectedProductInput, setSelectedProductInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [orderProductMetaData, setOrderProductMetaData] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [allWarehouse, setAllWarehouse] = useState([]);
  const [file, setFile] = useState(null);
  const [vat, setVat] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [selectedWarehouse, setSelectedWarehosue] = useState("");

  let requestMethods = new RequestMethods();

  function getTotalQuantity() {
    let totalQuantity = 0;
    orderProductMetaData.forEach(
      (metadata) => (totalQuantity = totalQuantity + metadata.productQuantity)
    );
    return totalQuantity;
  }
  function getSubtotal() {
    let subtotal = 0;
    orderProductMetaData.forEach(
      (metadata) => (subtotal = subtotal + metadata.productTotal)
    );
    return subtotal;
  }

  const changeWarehouse = (e) => {
    setSelectedWarehosue(e.target.value);
    setSelectedProductInput("");
  };

  const handleOrderProductDelete = (id) => {
    //delete meta data
    const filteredMetaData = orderProductMetaData.filter(
      (metadata) => metadata.id != id
    );
    setOrderProductMetaData(filteredMetaData);

    //delete ordered product;
    let filteredOrderedProduct = orderProducts.filter(
      (product) => product.id != id
    );
    setOrderProducts(filteredOrderedProduct);
  };

  const getSelectedProducts = (e) => {
    if (selectedProductInput) {
      let filteredProductWithInput = allProducts.filter((product) =>
        product.name
          .toLowerCase()
          .startsWith(selectedProductInput.toLowerCase())
      );

      setSelectedProducts(filteredProductWithInput);
    } else {
      setSelectedProducts([]);
    }
  };

  const selectProduct = (id) => {
    //check if the product is already there
    let productIsPresent = orderProducts.find((product) => product.id == id);
    if (productIsPresent) {
      setSelectedProducts([]);
      setSelectedProductInput("");
      return;
    }

    //push the product to the table
    let product = allProducts.find((product) => product.id == id);
    setOrderProducts((products) => [...products, product]);
    setSelectedProductInput("");
  };

  const calcTax = () => {
    const subTotal = getSubtotal();
    const vatValue = subTotal * (Number(vat) / 100);
    return vatValue.toFixed(2);
  };

  const getGrandTotal = () => {
    return Number(getSubtotal()) + Number(calcTax()) - Number(discount || 0);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const date = new Date().toLocaleDateString();
    const ref = `sr-${Math.floor(Math.random() * 99999999 + 1)}`;
    formData.append("file", file);
    formData.append("date", date);
    formData.append("reference", ref);
    formData.append("grand_total", getSubtotal());
    formData.append("total_discount", discount);
    formData.append("purchase_product", JSON.stringify(orderProductMetaData));

    const purchasePostApi = "/purchase/add-purchase";
    const response = await requestMethods.postFunction(
      purchasePostApi,
      formData
    );
    if (response.success) {
      e.target.reset();
      setFile();
      navigage("/purchase/manage-purchase");
      toast.success("Purchase added successfully!");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    getSelectedProducts();
  }, [selectedProductInput]);

  useEffect(() => {
    let productsApi = "product/product-list";
    requestMethods.getFunction(productsApi, setAllProducts);

    let supplierAPi = "/supplier";
    requestMethods.getFunction(supplierAPi, setAllSuppliers);

    let warehouseAPi = "/warehouse";
    requestMethods.getFunction(warehouseAPi, setAllWarehouse);
  }, []);

  return (
    <>
      <div className="p-4">
        <h4 className="mb-3 mt-3">Add Purchase</h4>
        <div>
          <p className="mb-4 mt-3 italic">
            <small>
              The field labels marked with * are required input fields.
            </small>
          </p>
          <form id="purchase-form" onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="mb-2">
                  <label htmlFor="Warehouse" className="form-label mb-2">
                    Warehouse *
                  </label>
                  <select
                    className="custom-select"
                    id="Warehouse"
                    name="warehouse"
                    onChange={changeWarehouse}
                  >
                    <option value="">Select warehouse...</option>
                    {allWarehouse?.map((warehouse) => (
                      <option value={warehouse.id}>{warehouse.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-2">
                  <label htmlFor="Supplier" className="form-label mb-2">
                    Supplier *
                  </label>
                  <select
                    className="custom-select"
                    id="Supplier"
                    name="supplier"
                  >
                    <option value="">Select supplier...</option>
                    {allSuppliers.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-2">
                  <label htmlFor="purchasestatus" className="form-label mb-2">
                    Purchase Status
                  </label>
                  <select
                    className="custom-select"
                    id="purchasestatus"
                    name="purchasestatus"
                  >
                    <option value="recieved">Recieved</option>
                    <option value="partial">Partial</option>
                    <option value="pending">Pending</option>
                    <option value="ordered">Ordered</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-2">
                  <label htmlFor="file">
                    Attach Document{" "}
                    <i
                      className="fa-regular fa-circle-question"
                      data-toggle="tooltip"
                      title="Only png, jpeg, pdf, txt file is supported!"
                    ></i>
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="form-control"
                    onChange={(e) => setFile(e.target.files[0])}
                    accept="image/png, image/jpeg, application/pdf, application/txt"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-3">
                  <label htmlFor="supplierInvoice" className="form-label mb-2">
                    Supplier Invoice
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="supplierInvoice"
                    name="supplierInvoice"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-2">
                  <label htmlFor="selectProduct" className="form-label mb-2">
                    Select Product
                  </label>
                  <div className="input-group">
                    <button className="btn btn-secondary">
                      <i className="fa fa-barcode"></i>
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      id="selectProduct"
                      name="selectProduct"
                      value={selectedProductInput}
                      onChange={(e) => setSelectedProductInput(e.target.value)}
                      placeholder="please type product code and select"
                    />

                    {!selectedProducts?.length || (
                      <ul className="list-group position-absolute w-100 mt-5">
                        {selectedProducts.map((product) => (
                          <li
                            key={product.id}
                            className="list-group-item pointer"
                            onClick={() => selectProduct(product.id)}
                          >
                            {product.name} ( {product.code} )
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table mt-3">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Code</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Net Unit Cost</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Tax</th>
                    <th scope="col">SubTotal</th>
                    <th scope="col">Batch</th>
                    <th scope="col">Expire Date</th>
                    <th scope="col">Available Quantity</th>
                    <th scope="col">Free Quantity</th>
                    <th scope="col">
                      <i className="fa-regular fa-trash-can"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!orderProducts.length ? (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        No data available in table
                      </td>
                    </tr>
                  ) : (
                    orderProducts.map((product) => (
                      <OrderedProductRow
                        key={product.id}
                        {...{
                          setOrderProductMetaData,
                          product,
                          handleOrderProductDelete,
                          orderProductMetaData,
                        }}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>

            <table className="table table-bordered mb-5  totals">
              <tbody>
                <tr>
                  <td>
                    <strong>Items</strong>{" "}
                    <span className="pull-right" id="item">
                      {orderProducts.length}({getTotalQuantity()})
                    </span>
                  </td>
                  <td>
                    <strong>Total</strong>
                    <span className="pull-right" id="subtotal">
                      ({getSubtotal()})
                    </span>
                  </td>
                  <td>
                    <strong>Order Tax</strong>
                    <span className="pull-right" id="order_tax">
                      {" "}
                      ({calcTax()})
                    </span>
                  </td>
                  <td>
                    <strong>Order Discount</strong>
                    <span className="pull-right" id="order_discount">
                      {" "}
                      ({discount || 0})
                    </span>
                  </td>
                  <td>
                    <strong>Grand Total</strong>
                    <span className="pull-right" id="grand_total">
                      {" "}
                      {getGrandTotal() || 0}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row">
              <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="orderTax" className="form-label mb-2">
                    Order Tax
                  </label>
                  <select
                    className="custom-select"
                    id="orderTax"
                    name="orderTax"
                    aria-label="Default select example"
                    onChange={(e) => setVat(e.target.value)}
                  >
                    <option value={0}>No Tax</option>
                    <option value={10}>vat@10</option>
                    <option value={15}>vat@15</option>
                    <option value={20}>vat 20</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="discount" className="form-label mb-2">
                    Discount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="discount"
                    name="discount"
                    min={0}
                    onChange={(e) => setDiscount(e.target.valueAsNumber)}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="mb-2">
                  <label htmlFor="note" className="form-label mb-2">
                    Note
                  </label>
                  <textarea
                    className="form-control"
                    id="note"
                    name="note"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            </div>

            <button className="btn btn-success mt-3" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
