import React, { useEffect} from "react";

export default function Adjustment() {
  useEffect(() => {
    document.title = 'Adjustment';  
  }, []);

  return (
    <>
      <h1>Adjustment</h1>
    </>
  );
}