import React, { useEffect, useLayoutEffect, useState } from "react";

export default function TransferRow({
  setOrderProductMetaData,
  orderProductMetaData,
  product,
  handleOrderProductDelete,
}) {
  const [orderProductQuantity, setOrderProductQuantity] = useState(1);

  function setOrderProductMetaObject() {
    const obj = {
      id: product.id,
      productQuantity: orderProductQuantity,
      productTotal: orderProductQuantity * product.price,
    };

    //check for duplicate meta data
    const hasAlready = orderProductMetaData.find(
      (metadata) => metadata.id == product.id
    );
    if (!hasAlready) {
      setOrderProductMetaData([...orderProductMetaData, obj]);
    }
  }

  const updateProductMetaObject = () => {
    //find the specific product meta data with id and update the value
    const productQuantity = isNaN(orderProductQuantity)
      ? 0
      : orderProductQuantity;
    const updatedProductMetaData = orderProductMetaData.map((metadata) => {
      if (metadata.id === product.id) {
        return {
          id: product.id,
          productQuantity: productQuantity,
          productTotal: productQuantity * product.price,
        };
      } else {
        return metadata;
      }
    });

    setOrderProductMetaData(updatedProductMetaData);
  };

  function productQtyChangeHandler(qty) {
    if (qty > product.quantity) {
      alert("You exceeded product quantity!");
    } else {
      setOrderProductQuantity(qty);
    }
  }

  useEffect(() => {
    setOrderProductMetaObject();
  }, []);

  useLayoutEffect(() => {
    updateProductMetaObject();
  }, [orderProductQuantity]);

  return (
    <tr key={product.id}>
      <td>{product.name}</td>
      <td>{product.code}</td>
      <td>
        <input
          className="form-control"
          type="number"
          name="quantity"
          value={orderProductQuantity}
          onChange={(e) => productQtyChangeHandler(e.target.valueAsNumber)}
          min={1}
          id="quantity"
        />
      </td>
      <td>{product.price}</td>
      <td>0</td>
      <td>
        {isNaN(orderProductQuantity) ? 0 : orderProductQuantity * product.price}
      </td>
      <td onClick={() => handleOrderProductDelete(product.id)}>
        <button className="btn btn-danger">Delete</button>
      </td>
    </tr>
  );
}
