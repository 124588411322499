import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function Accounts() {
  const [accounts, setAccount] = useState([]);
  const [editAccount, seteditAccount] = useState([]);
  const requestMethods = new RequestMethods();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let closeModal = document.querySelector("#brandCloseModal");
    let formData = new FormData(e.target);

    if (editAccount.length) {
      const api = `/account/edit-account/${editAccount[0].id}`;
      const response = await requestMethods.patchFunction(api, formData);
      if (response.success) {
        e.target.reset();
        toast.success("Account updated successfully!");
        closeModal.click();
        getAccounts();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0].field].focus();
      }
    } else {
      const api = "/account/add-account";
      const response = await requestMethods.postFunction(api, formData);
      if (response.success) {
        e.target.reset();
        toast.success("Account added successfully");
        closeModal.click();
        getAccounts();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0].field].focus();
      }
    }
  };

  const handleDelete = (id) => {
    const api = `/account/delete-account/${id}`;
    const response = requestMethods.deleteFunction(api, getAccounts);
    if (response) {
      toast.success("Account deleted successfully!");
    }
  };

  const getAccounts = () => {
    const api = "/account";
    requestMethods.getFunction(api, setAccount);
  };

  const getSingleAccount = (id) => {
    const api = `/account/${id}`;
    requestMethods.getFunction(api, seteditAccount);
  };

  function getTotalInitialAmount() {
    const sum = accounts.reduce(
      (total, account) => total + account.initial_balance,
      0
    );
    return sum;
  }

  async function handleActive(e, id) {
    const api = `/account/edit-status/${id}`;
    const response = await requestMethods.patchFunction(api, {
      value: e.target.checked ? 1 : 0,
    });
    if (response.success) {
      toast.success("Account status changed");
    } else {
      toast.error("Something went wrong!");
    }
  }

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#account_modal"
          onClick={() => seteditAccount([])}
        >
          <i className="fa-solid fa-plus" />
          Add Account
        </button>
      </div>
      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Account No <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Name <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Innitial Balance <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Active Account</th>
              <th scope="col">Note</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!accounts.length ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  No data available in table
                </td>
              </tr>
            ) : (
              accounts?.map((account) => (
                <tr key={account.id}>
                  <td>{account.account_no}</td>
                  <td> {account.name}</td>
                  <td> {account.initial_balance}</td>
                  <td>
                    <label class="switch">
                      <input
                        type="checkbox"
                        defaultChecked={account.is_active}
                        onChange={(e) => handleActive(e, account.id)}
                      />
                      <span class="slider round"></span>
                    </label>
                  </td>
                  <td> {account.note}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#account_modal"
                            onClick={() => getSingleAccount(account.id)}
                          >
                            <i className="fa-solid fa-pen-to-square me-2" />
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => handleDelete(account.id)}
                            className="dropdown-item"
                            type="button"
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>Total</td>
              <td>{getTotalInitialAmount()}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="account_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog account_modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {editAccount.length ? "Edit Account" : "Add Account"}
              </h5>
              <button
                type="button"
                className="close"
                id="brandCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p class="italic">
                <small>
                  The field labels marked with * are required input fields.
                </small>
              </p>
              <form onSubmit={handleSubmit} id="productBrandForm">
                <div className="mb-3">
                  <label htmlFor="account_no" className="form-label">
                    Account No *
                  </label>
                  <input
                    type="text"
                    name="account_no"
                    className="form-control"
                    id="account_no"
                    defaultValue={editAccount[0]?.account_no}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name *
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    defaultValue={editAccount[0]?.name}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="initial_balance" className="form-label">
                    Initial Balance
                  </label>
                  <input
                    type="number"
                    name="initial_balance"
                    className="form-control"
                    id="initial_balance"
                    defaultValue={editAccount[0]?.initial_balance}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="note" className="form-label">
                    Note
                  </label>
                  <textarea
                    className="form-control"
                    name="note"
                    id="note"
                    rows="5"
                    defaultValue={editAccount[0]?.note || ""}
                  ></textarea>
                </div>
                <button className="btn btn-success" type="submit">
                  {editAccount.length ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
