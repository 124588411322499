import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function AddReturn() {
  const [returnSales, setReturnSales] = useState([]);
  const [warehouse, setAllWarehouse] = useState([]);
  const [customer, setAllCustomer] = useState([]);
  const [salesProducts, setSalesProducts] = useState([]);
  const [file, setFile] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  let requestMethods = new RequestMethods();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const date = new Date().toLocaleString();
    formData.set("date", date);
    formData.set("reference", returnSales[0]?.reference);
    formData.set("customer", returnSales[0]?.customer);
    formData.set("warehouse", returnSales[0]?.warehouse);
    formData.set("grand_total", returnSales[0]?.grand_total);
    formData.set("document", file);

    const api = "sales/add-return-sales";
    const response = await requestMethods.postFunction(api, formData);
    if (response.success) {
      e.target.reset();
      setFile();
      navigate("/sales/return");
      toast.success("Return sales added successfully!");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    const returnSalesapi = `sales/sales-ref/${id}`;
    requestMethods.getFunction(returnSalesapi, setReturnSales);

    requestMethods.getFunction("/warehouse", setAllWarehouse);
    requestMethods.getFunction("/customer", setAllCustomer);
    requestMethods.getFunction(`/sales/sales-product/${id}`, setSalesProducts);
  }, []);

  return (
    <>
      <form id="purchase-form" className="p-4" onSubmit={handleFormSubmit}>
        <div className="row mt-3">
          <div className="col-sm-2">
            <div className="mb-2">
              <h5>Reference</h5>
              <p className="font-weight-bold" style={{ fontSize: "1.3rem" }}>
                {returnSales[0]?.reference}
              </p>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="mb-2">
              <label htmlFor="warehouse" className="form-label mb-2">
                Warehouse
              </label>
              <select
                className="custom-select"
                id="warehouse"
                name="warehouse"
                value={returnSales[0]?.warehouse}
                disabled
              >
                <option value="">Select warehouse...</option>
                {warehouse?.map((warehouse) => (
                  <option value={warehouse.id}>{warehouse.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="mb-2">
              <label htmlFor="customer" className="form-label mb-2">
                Customer
              </label>
              <select
                value={returnSales[0]?.customer}
                className="custom-select"
                id="customer"
                name="customer"
                disabled
              >
                {customer?.map((customer) => (
                  <option value={customer.id}>{customer.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <h5 className="mt-5">Sales products</h5>
        <div className="table-responsive">
          <table className="table mt-3">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Code</th>
                <th scope="col">Quantity</th>
                <th scope="col">Net Unit Cost</th>
                <th scope="col">Discount</th>
                <th scope="col">Tax</th>
                <th scope="col">SubTotal</th>
              </tr>
            </thead>
            <tbody>
              {!salesProducts.length ? (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No data available in table
                  </td>
                </tr>
              ) : (
                salesProducts.map((product) => (
                  <tr>
                    <td>{product.name}</td>
                    <td>{product.code}</td>
                    <td style={{ maxWidth: "100px" }}>
                      <input
                        type="number"
                        className="form-control"
                        value={product.quantity}
                        disabled
                      />
                    </td>
                    <td>{product.price}</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>{product.price * product.quantity}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <div className="mb-2">
              <label htmlFor="document">
                Attach Document{" "}
                <i
                  className="fa-regular fa-circle-question"
                  data-toggle="tooltip"
                  title="Only png, jpeg, pdf, txt file is supported!"
                ></i>
              </label>
              <input
                type="file"
                name="document"
                id="document"
                className="form-control"
                onChange={(e) => setFile(e.target.files[0])}
                accept="image/png, image/jpeg, application/pdf, application/txt"
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="mb-2">
              <label htmlFor="return_note" className="form-label mb-2">
                Return Note
              </label>
              <textarea
                className="form-control"
                id="return_note"
                name="return_note"
                rows="4"
                cols="50"
              ></textarea>
            </div>
          </div>
        </div>

        <button className="btn btn-success mt-3 mb-5" type="submit">
          Submit
        </button>
      </form>
    </>
  );
}
