import React, { useEffect} from "react";

export default function DailySalesReport() {
  useEffect(() => {
    document.title = 'Daily Sales Report';  
  }, []);

  return (
    <>
      <h1>Daily Sales Report</h1>
    </>
  );
}