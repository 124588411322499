import React, { useEffect} from "react";

export default function AccountType() {
  useEffect(() => {
    document.title = 'Page Not Found';  
  }, []);

  return (
    <>
      <h1>Page Not Found</h1>
    </>
  );
}