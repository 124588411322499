import { useNavigate } from "react-router-dom";

export default function HeaderFixed() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <div className="header__fixed">
      <button className="btn btn-subscribe">
        <img
          src="https://app.waalaxy.com/static/media/crown.bc3f75d0068933fbe07b25f665fb51fe.svg"
          alt=""
        />
        <span>Subscribe</span>
      </button>
      <button className="btn btn-bg">Ctgsoft</button>
      <div className="profile">
        <img
          src="https://media-exp1.licdn.com/dms/image/C5603AQFkrNFi-RtDGg/profile-displayphoto-shrink_800_800/0/1626701488898?e=1674691200&v=beta&t=ti2dAD4A_5av4IQ6aHR2R22DSv8tlMY98K90vyltDNg"
          alt=""
        />
        <span>Arman Hossain</span>
      </div>
      <p style={{ fontSize: "1.3rem" }} role="button" onClick={handleLogout}>
        <i class="fa-solid fa-arrow-right-from-bracket"></i>
      </p>
    </div>
  );
}
