import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function CouponList() {
  const [allCoupon, setAllCoupon] = useState([]);
  const [editCoupon, setEditCoupon] = useState([]);
  const codeFieldRef = useRef(null);

  let requestMethods = new RequestMethods();
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const dateString = `${year}-${month}-${day}`;

  const generateCode = () => {
    const code = Math.random().toString().substr(2, 6);
    codeFieldRef.current.value = code;
  };

  const getCouponList = () => {
    requestMethods.getFunction("/sales/coupon", setAllCoupon);
  };

  function getSingleCounponList(id) {
    requestMethods.getFunction(`/sales/coupon/${id}`, setEditCoupon);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (editCoupon.length) {
      const api = `/sales/edit-coupon/${editCoupon[0].id}`;
      const response = await requestMethods.patchFunction(api, formData);

      if (response.success) {
        e.target.reset();
        toast.success("Coupon updated successfully");
        document.querySelector("#expenseCloseModal").click();
        getCouponList();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0]?.field].focus();
      }
    } else {
      const api = "/sales/add-coupon";
      const response = await requestMethods.postFunction(api, formData);

      if (response.success) {
        toast.success("Coupon added successfully");
        getCouponList();
        document.querySelector("#expenseCloseModal").click();
        e.target.reset();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0]?.field].focus();
      }
    }
  };

  function handleDelete(id) {
    const response = requestMethods.deleteFunction(
      `/sales/delete-coupon/${id}`,
      getCouponList
    );
    if (response) {
      toast.success("Coupon deleted successfully");
    } else {
      toast.error("Coupon deleting failed!");
    }
  }

  useEffect(() => {
    getCouponList();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#expense_modal"
          onClick={() => setEditCoupon([])}
        >
          <i className="fa-solid fa-plus" />
          Add Coupon
        </button>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>

        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div>
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Coupon Code <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Type <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Amount <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Quantity <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Available <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Expire Date <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Created By <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allCoupon.length ? (
              allCoupon?.map((coupon) => (
                <tr key={coupon.id}>
                  <td>{coupon.code}</td>
                  <td>
                    {coupon.type === "fixed" ? (
                      <span className="badge badge-primary">{coupon.type}</span>
                    ) : (
                      <span className="badge badge-info">{coupon.type}</span>
                    )}
                  </td>
                  <td>{coupon.amount}</td>
                  <td>{coupon.quantity}</td>
                  <td>{coupon.available}</td>
                  <td>{new Date(`${coupon.expired}`).toLocaleDateString()}</td>
                  <td>{coupon.created_by}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#expense_modal"
                            onClick={() => getSingleCounponList(coupon.id)}
                          >
                            <i className="fa-solid fa-pen-to-square me-2" />
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleDelete(coupon.id)}
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="100%" className="text-center">
                  No data added
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="expense_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="expense_modal">
                {editCoupon.length ? "Edit Coupon" : "Add Coupon"}
              </h5>
              <button
                type="button"
                className="close"
                id="expenseCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p class="italic">
                <small>
                  The field labels marked with * are required input fields.
                </small>
              </p>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="code" className="form-label mb-2">
                      Coupon Code *
                    </label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        name="code"
                        id="code"
                        ref={codeFieldRef}
                        defaultValue={editCoupon[0]?.code}
                        type="text"
                      />
                      <div class="input-group-append">
                        <button
                          id="genbutton"
                          type="button"
                          class="btn btn-default"
                          onClick={generateCode}
                          style={{
                            border: "1px solid #8dbf42",
                            color: "#8dbf42",
                          }}
                        >
                          Generate
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="type" className="form-label">
                      Type
                    </label>
                    <select
                      className="custom-select"
                      id="type"
                      name="type"
                      aria-label="Default select example"
                    >
                      <option
                        value="fixed"
                        selected={editCoupon[0]?.type === "fixed" && "selected"}
                      >
                        Fixed
                      </option>
                      <option
                        value="percentage"
                        selected={
                          editCoupon[0]?.type === "percentage" && "selected"
                        }
                      >
                        Percentage
                      </option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="amount" className="form-label mb-2">
                      Amount *
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="amount"
                      name="amount"
                      min={0}
                      defaultValue={editCoupon[0]?.amount}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="quantity" className="form-label mb-2">
                      Quanity *
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="quantity"
                      name="quantity"
                      min={1}
                      defaultValue={editCoupon[0]?.quantity}
                    />
                  </div>
                  <div className="mb-3 col-md-12">
                    <div class="form-group">
                      <label htmlFor="expired" className="form-label">
                        Expire Date
                      </label>
                      <input
                        type="date"
                        min={dateString}
                        className="form-control"
                        id="expired"
                        name="expired"
                        defaultValue={editCoupon[0]?.expired}
                      />
                    </div>
                  </div>
                </div>
                <button className="btn btn-success" type="submit">
                  {editCoupon.length ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
