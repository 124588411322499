import axios from "axios";

const request = axios.create({
  baseURL: "http://inventoryapi.educativeit.com/",
});

export default request;

// baseURL: "http://localhost:5000/",
// baseURL: "http://inventoryapi.ideacastit.com/",
// baseURL: "inventoryapi.educativeit.com",
