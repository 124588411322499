import React, { useEffect, useState } from "react";
import request from "../../axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import RequestMethods from "../../helper";

export default function EditCustomer() {
  const [updateCustomer, setUpdateCustomer] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const requestMethods = new RequestMethods();

  async function getSingleCustomers() {
    let api = `/customer/${id}`;
    requestMethods.getFunction(api, setUpdateCustomer);
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    const api = `/customer/edit-customer/${id}`;
    const response = await requestMethods.patchFunction(api, formData);
    if (response.success) {
      e.target.reset();
      navigate("/customer/customer-list");
      toast.success("Customer updated successfully");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    getSingleCustomers();
  }, []);

  return (
    <>
      <form className="my-5" id="addCustomerForm" onSubmit={handleUpdate}>
        <div className="row gy-3">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="customerType" className="form-label mb-2">
                Customer Group
              </label>
              <select
                className="custom-select"
                id="customerType"
                name="group"
                aria-label="Default select example"
                value={updateCustomer[0]?.groupType}
                required
              >
                <option value="general">General</option>
                <option value="distributor">Distributor</option>
                <option value="reseller">Reseller</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="customerName" className="form-label mb-2">
                Customer Name
              </label>
              <input
                type="text"
                className="form-control"
                id="customerName"
                name="name"
                defaultValue={updateCustomer[0]?.name}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="companyName" className="form-label mb-2">
                Company Name
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                defaultValue={updateCustomer[0]?.company}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="email" className="form-label mb-2">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                defaultValue={updateCustomer[0]?.email}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="phoneNumber" className="form-label mb-2">
                Phone Number
              </label>
              <input
                type="tel"
                pattern="[0-9]{11}"
                className="form-control"
                placeholder="example: 01633564509"
                id="phoneNumber"
                name="phoneNumber"
                defaultValue={updateCustomer[0]?.phone_number}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="tax" className="form-label mb-2">
                Tax Number
              </label>
              <input
                type="tel"
                className="form-control"
                id="tax"
                name="tax"
                defaultValue={updateCustomer[0]?.tax_number}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="address" className="form-label mb-2">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                defaultValue={updateCustomer[0]?.address}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="city" className="form-label mb-2">
                City
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                defaultValue={updateCustomer[0]?.city}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="state" className="form-label mb-2">
                State
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                name="state"
                defaultValue={updateCustomer[0]?.state}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="postalCode" className="form-label mb-2">
                Postal Code
              </label>
              <input
                type="number"
                className="form-control"
                id="postalCode"
                name="postalCode"
                defaultValue={updateCustomer[0]?.postal_code}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="advance" className="form-label mb-2">
                Advance
              </label>
              <input
                type="number"
                className="form-control"
                id="advance"
                name="advance"
                defaultValue={updateCustomer[0]?.advance}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="country" className="form-label mb-2">
                Country
              </label>
              <input
                type="text"
                className="form-control"
                id="country"
                name="country"
                defaultValue={updateCustomer[0]?.country}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <button className="btn btn-success" type="submit">
              Update Customer
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
