import React, { useEffect} from "react";

export default function ExpenseReport() {
  useEffect(() => {
    document.title = 'Expense Report';  
  }, []);

  return (
    <>
      <h1>Expense Report</h1>
    </>
  );
}