import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ProductUnit() {
  const [unitData, setunitData] = useState([]);
  const [editUnitData, seteditUnitData] = useState([]);
  const requestMethods = new RequestMethods();

  const handleDeleteUnit = (id) => {
    const api = `/product/delete-unit/${id}`;
    const response = requestMethods.deleteFunction(api, getProductsUnit);
    if (response) {
      toast.success("Unit deleted successfully!");
    } else {
      toast.error("Unit deleting failed!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const productUnitForm = document.getElementById("productUnitForm");
    let formData = new FormData(e.target);

    if (editUnitData.length) {
      const api = `/product/edit-unit/${editUnitData[0].id}`;
      const response = await requestMethods.patchFunction(api, formData);
      if (response) {
        toast.success("Unit successfully updated!");
        seteditUnitData([]);
        productUnitForm.reset();
        document.querySelector("#add_unit .close").click();
        getProductsUnit();
      } else {
        toast.error("Something went wrong!");
      }
    } else {
      const api = `/product/add-unit`;
      const response = await requestMethods.postFunction(api, formData);
      if (response.success) {
        toast.success("Unit successfully added!");
        getProductsUnit();
        productUnitForm.reset();
        document.querySelector("#add_unit .close").click();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
          e.target[err.field].focus();
        });
      }
    }
  };

  async function getProductsUnit(id) {
    if (id) {
      console.log("i am inside");
      const productUnitEdit = `/product/product-unit/${id}`;
      requestMethods.getFunction(productUnitEdit, seteditUnitData);
    } else {
      const api = "/product/product-unit";
      requestMethods.getFunction(api, setunitData);
    }
  }

  useEffect(() => {
    getProductsUnit();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#add_unit"
          onClick={() => seteditUnitData([])}
        >
          <i className="fa-solid fa-plus" />
          Add Unit
        </button>
      </div>
      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">Code</th>
              <th scope="col">
                Name <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!unitData.length ? (
              <tr>
                <td colspan="2" className="text-center">
                  no data
                </td>
              </tr>
            ) : (
              unitData.map((unit) => (
                <tr key={unit.code}>
                  <td>{unit.code}</td>
                  <td>{unit.name}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            onClick={() => getProductsUnit(unit.id)}
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#add_unit"
                          >
                            <i className="fa-solid fa-pen-to-square me-2" />
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleDeleteUnit(unit.id)}
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="add_unit"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {editUnitData.length ? "Edit Unit" : "Add Unit"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} id="productUnitForm">
                <div className="mb-3">
                  <label htmlFor="code" className="form-label">
                    Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="code"
                    name="code"
                    defaultValue={editUnitData[0]?.code}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    defaultValue={editUnitData[0]?.name}
                  />
                </div>
                <button className="btn btn-success" type="submit">
                  {editUnitData.length ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
