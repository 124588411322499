import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ProductSubcategory() {
  const [parentCategories, setParentCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [editSubcategory, setEditSubcategory] = useState([]);
  const requestMethods = new RequestMethods();

  function getParentCategories() {
    let api = "product/product-category";
    requestMethods.getFunction(api, setParentCategories);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const subcategoryForm = document.getElementById("subcategoryForm");
    let formData = new FormData(subcategoryForm);

    if (!editSubcategory.length) {
      const api = "/product/add-subcategory";
      let response = await requestMethods.postFunction(api, formData);

      if (response.success) {
        subcategoryForm.reset();
        document.querySelector("button.close").click();
        toast.success("Subcategory added successfully");
        getSubcategories();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
          e.target[err.field].focus();
        });
      }
    } else {
      const api = `/product/edit-subcategory/${editSubcategory[0].id}`;
      let response = await requestMethods.patchFunction(api, formData);

      if (response) {
        subcategoryForm.reset();
        document.querySelector("button.close").click();
        toast.success("Subcategory updated successfully");
        getSubcategories();
      } else {
        toast.error("Subcategory updating failed!");
      }
    }
  }

  function getSubcategories() {
    let api = "product/product-subcategory";
    requestMethods.getFunction(api, setSubcategories);
  }

  function deleteSubcategory(id) {
    let api = `product/delete-subcategory/${id}`;
    const response = requestMethods.deleteFunction(api, getSubcategories);
    if (response) {
      toast.success("Subcategory deleted successfully");
    }
  }

  function getProductSingleSubcategory(id) {
    let api = `product/product-subcategory/${id}`;
    requestMethods.getFunction(api, setEditSubcategory);
  }

  useEffect(() => {
    getSubcategories();
    getParentCategories();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#add_subcategory"
        >
          <i className="fa-solid fa-plus" />
          Add Subcategory
        </button>
      </div>
      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Subcategory <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Parent Category <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {subcategories.map((subcategory) => (
              <tr>
                <td>{subcategory.name}</td>
                <td>{subcategory.parent_category}</td>
                <td>
                  <div className="dropdown dropstart">
                    <button
                      className="btn btn-warning dropdown-toggle btn-sm"
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenu2"
                    >
                      <li>
                        <button
                          onClick={() =>
                            getProductSingleSubcategory(subcategory.id)
                          }
                          className="dropdown-item"
                          type="button"
                          data-toggle="modal"
                          data-target="#add_subcategory"
                        >
                          <i className="fa-solid fa-pen-to-square me-2" />
                          Edit
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => deleteSubcategory(subcategory.id)}
                        >
                          <i className="fa-solid fa-trash me-2" />
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="add_subcategory"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {editSubcategory.length ? "Edit" : "Add"} Subcategory
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} id="subcategoryForm">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    defaultValue={editSubcategory[0]?.name}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="warehouse" className="form-label">
                    Parent Category
                  </label>
                  <select
                    className="custom-select"
                    aria-label="Default select example"
                    name="parentCategory"
                    value={editSubcategory[0]?.parent_category}
                    required
                  >
                    <option value="" selected>
                      Select Parent Category
                    </option>
                    {parentCategories.map((parent) => (
                      <option value={parent.id}>{parent.name}</option>
                    ))}
                  </select>
                </div>
                <button className="btn btn-success" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
