import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function UpdateTransfer() {
  const [editTransfer, setEditTransfer] = useState([]);
  const [allWarehouse, setAllWarehouse] = useState([]);
  const [file, setFile] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const request = new RequestMethods();

  const handleFormUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("file", file);
    const api = `/transfer/edit-transfer/${id}`;
    const response = await request.patchFunction(api, formData);

    if (response.success) {
      toast.success("Transfer list updated");
      navigate("/transfer/transfer-list");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    request.getFunction(`/transfer/${id}`, setEditTransfer);

    let warehouseAPi = "/warehouse";
    request.getFunction(warehouseAPi, setAllWarehouse);
  }, []);

  return (
    <div className="p-4">
      <h4 className="mb-3 mt-3">Update Transfer</h4>
      <div>
        <p className="mb-4 mt-3 italic">
          <small>
            The field labels marked with * are required input fields.
          </small>
        </p>
        <form id="purchase-form" onSubmit={handleFormUpdate}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-2">
                <h6>Reference</h6>
                <p className="font-weight-bold" style={{ fontSize: "1.3rem" }}>
                  {editTransfer[0]?.reference}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="status" className="form-label mb-2">
                  Status
                </label>
                <select className="custom-select" id="status" name="status">
                  <option
                    selected={
                      editTransfer[0]?.status === "completed" && "selected"
                    }
                    value="completed"
                  >
                    completed
                  </option>
                  <option
                    selected={
                      editTransfer[0]?.status === "pending" && "selected"
                    }
                    value="pending"
                  >
                    pending
                  </option>
                  <option
                    selected={editTransfer[0]?.status === "sent" && "selected"}
                    value="sent"
                  >
                    sent
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="from_warehouse" className="form-label mb-2">
                  From Warehouse *
                </label>
                <select
                  className="custom-select"
                  id="from_warehouse"
                  name="from_warehouse"
                >
                  <option value="">Select wareshouse...</option>
                  {allWarehouse.map((warehouse) => (
                    <option
                      selected={
                        editTransfer[0]?.warehouse_from === warehouse.id
                          ? "selected"
                          : ""
                      }
                      key={warehouse.id}
                      value={warehouse.id}
                    >
                      {warehouse.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="to_warehouse" className="form-label mb-2">
                  To Warehouse *
                </label>
                <select
                  className="custom-select"
                  id="to_warehouse"
                  name="to_warehouse"
                >
                  <option value="">Select wareshouse...</option>
                  {allWarehouse.map((warehouse) => (
                    <option
                      selected={
                        editTransfer[0]?.warehouse_to === warehouse.id
                          ? "selected"
                          : ""
                      }
                      key={warehouse.id}
                      value={warehouse.id}
                    >
                      {warehouse.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="file">
                  Attach Document{" "}
                  <i
                    className="fa-regular fa-circle-question"
                    data-toggle="tooltip"
                    title="Only png, jpeg, pdf, txt file is supported!"
                  ></i>
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="form-control"
                  onChange={(e) => setFile(e.target.files[0])}
                  accept="image/png, image/jpeg, application/pdf, application/txt"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="mb-2">
                <label htmlFor="note" className="form-label mb-2">
                  Note
                </label>
                <textarea
                  className="form-control"
                  id="note"
                  name="note"
                  rows="4"
                  cols="50"
                  defaultValue={editTransfer[0]?.note || ""}
                ></textarea>
              </div>
            </div>
          </div>

          <button className="btn btn-success mt-3" type="submit">
            Update
          </button>
        </form>
      </div>
    </div>
  );
}
