import { Link } from "react-router-dom";
import usePurchase from "../../Custom hook/usePurchase";

export default function PurchaseList() {
  const api = "/purchase";
  const {
    allPurchases,
    accounts,
    setactivePaymentId,
    handleDeletePurchase,
    getPurchaseGrandTotal,
    handlePaymentSubmit,
    getGrandTotal,
    getTotalPaid,
  } = usePurchase(api);

  return (
    <>
      <div className="add__button">
        <Link to="/purchase/add-purchase">
          <button type="button" className="btn">
            <i className="fa-solid fa-plus" />
            Add Purchase
          </button>
        </Link>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>

        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Date <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Reference <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Supplier <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Purchase Status</th>
              <th scope="col">
                Grand Total <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Paid <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Due</th>
              <th scope="col">Warehouse</th>
              <th scope="col">Total Free Quantity</th>
              <th scope="col">
                Invoice Payment Status <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Supplier Invoice <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allPurchases.length ? (
              allPurchases.map((purchase) => (
                <tr>
                  <td>{purchase.date}</td>
                  <td>{purchase.reference}</td>
                  <td>{purchase.supplier}</td>
                  <td>{purchase.purchase_status}</td>
                  <td>{purchase.grand_total}</td>
                  <td>{purchase.paid || 0}</td>
                  <td>{purchase.grand_total - purchase.paid}</td>
                  <td>{purchase.warehouse}</td>
                  <td>N/A</td>
                  <td>
                    {purchase.payment_status ? (
                      <span className="badge badge-success">Paid</span>
                    ) : (
                      <span className="badge badge-danger">Due</span>
                    )}
                  </td>
                  <td>{purchase.supplier_invoice}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <Link to={`/purchase/edit-purchase/${purchase.id}`}>
                            <button className="dropdown-item" type="button">
                              <i className="fa-solid fa-pen-to-square mr-2" />
                              Edit
                            </button>
                          </Link>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#add_payment"
                            onClick={() => setactivePaymentId(purchase.id)}
                          >
                            <i class="fa fa-plus mr-2"></i>
                            Add Payment
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() =>
                              handleDeletePurchase(purchase.reference)
                            }
                          >
                            <i className="fa-solid fa-trash mr-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11} className="text-center">
                  No purchase added
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>Total</td>
              <td>{getGrandTotal()}</td>
              <td>{getTotalPaid()}</td>
              <td>{getGrandTotal() - getTotalPaid()}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="add_payment"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog account_modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Payment
              </h5>
              <button
                type="button"
                className="close"
                id="CloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p class="italic">
                <small>
                  The field labels marked with * are required input fields.
                </small>
              </p>
              <form onSubmit={handlePaymentSubmit}>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="purchase_total" className="form-label">
                      Received Amount
                    </label>
                    <input
                      type="number"
                      value={getPurchaseGrandTotal()}
                      className="form-control"
                      id="purchase_total"
                      disabled
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="paid" className="form-label">
                      Paying Amount *
                    </label>
                    <input
                      type="number"
                      name="paid"
                      className="form-control"
                      id="paid"
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="payment_method" className="form-label">
                      Paid By
                    </label>
                    <select
                      className="custom-select"
                      id="payment_method"
                      name="payment_method"
                    >
                      <option value="cash">Cash</option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="account" className="form-label">
                      Account
                    </label>
                    <select
                      className="custom-select"
                      id="account"
                      name="account"
                    >
                      {accounts?.map((account) => (
                        <option key={account.id} value={account.id}>
                          {account.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-md-12">
                    <label htmlFor="note" className="form-label">
                      Payment Note
                    </label>
                    <textarea
                      className="form-control"
                      name="note"
                      id="note"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <button className="btn btn-success" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
