import { NavLink, Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const handleClick = () => {
    const container = document.querySelector("#container");
    container.classList.toggle("sidebar-closed");
    container.classList.toggle("sbar-open");
  };

  return (
    <nav id={`sidebar`}>
      <ul className="navbar-item theme-brand flex-row  text-center">
        <li className="nav-item inventory_logo theme-logo d-flex justify-content-between">
          <Link to="/" className="logo">
            Digitalhero
          </Link>

          <span
            className="sidebarCollapse mobileSidebarCollaps"
            id="sidebarCollapsBtn"
            data-placement="bottom"
            onClick={handleClick}
            role="button"
          >
            <i className="las la-bars" />
          </span>
        </li>
      </ul>
      <ul
        className="list-unstyled menu-categories sidebarlistUL ps ps--active-y"
        id="accordionExample"
      >
        <li className="menu">
          <NavLink to="/" className="dropdown-toggle">
            <div className="">
              <i className="las la-home" />
              <span>Dashboards</span>
            </div>
          </NavLink>
        </li>
        <li className="menu">
          <a
            href="#product"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-cube" />
              <span>Product</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="product"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/product/product-list"> Product List </NavLink>
            </li>
            <li>
              <NavLink to="/product/add-product"> Add Product </NavLink>
            </li>
            <li>
              <NavLink to="/product/product-unit"> Unit </NavLink>
            </li>
            <li>
              <NavLink to="/product/product-category"> Category </NavLink>
            </li>
            <li>
              <NavLink to="/product/sub-category"> Sub Category </NavLink>
            </li>
            <li>
              <NavLink to="/product/child-category"> Child Category </NavLink>
            </li>
            <li>
              <NavLink to="/product/product-size"> Size </NavLink>
            </li>
            <li>
              <NavLink to="/product/product-color"> Color </NavLink>
            </li>
            <li>
              <NavLink to="/product/product-brand"> Brand </NavLink>
            </li>
            {/* <li>
              <NavLink to="/product/print-barcode"> Print Barcode </NavLink>
            </li> */}
          </ul>
        </li>
        <li className="menu">
          <a
            href="#warehouse"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-warehouse"></i>
              <span>Warehouse</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="warehouse"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/warehouse/manage-warehouse">
                {" "}
                Manage Warehouse{" "}
              </NavLink>
            </li>
          </ul>
        </li>

        <li className="menu">
          <a
            href="#quicklinks"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-link" />
              <span>Quick Links</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="quicklinks"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/quicklinks/customer-receivable">
                {" "}
                Customer Receivable{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/quicklinks/suppliers-payable">
                {" "}
                Supplier’s Payable{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/quicklinks/todays-summery">
                {" "}
                Today’s Summery{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/quicklinks/return-order"> Return Order </NavLink>
            </li>
            <li>
              <NavLink to="/quicklinks/pos"> POS </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#suppliers"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-truck" />
              <span>Suppliers</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="suppliers"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/suppliers/add-suppliers"> Add Suppliers </NavLink>
            </li>
            <li>
              <NavLink to="/suppliers/suppliers-list"> Suppliers List </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#customer"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-users" />
              <span>Customer</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="customer"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/customer/customer-list"> Customer List </NavLink>
            </li>
            <li>
              <NavLink to="/customer/add-customer"> Add Customer </NavLink>
            </li>
            <li>
              <NavLink to="/customer/due-recieved-list">
                {" "}
                Due Received List{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/customer/business-due-pay-list">
                {" "}
                Business Due Pay List{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/customer/customer-group"> Customer Group </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#quotation"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-file-alt" />
              <span>Quotation</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="quotation"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/quotation/add-quotation"> Add Quotation </NavLink>
            </li>
            <li>
              <NavLink to="/quotation/manage-quotation">
                {" "}
                Manage Quotation{" "}
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#purchase"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-shopping-bag" />
              <span>Purchase</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="purchase"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/purchase/add-purchase"> Add Purchase </NavLink>
            </li>
            <li>
              <NavLink to="/purchase/manage-purchase">
                {" "}
                Manage Purchase{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/purchase/purchase-invoice">
                {" "}
                Purchase Invoice{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/purchase/purchase-return-type">
                {" "}
                Purchase Return Type{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/purchase/purchase-return-list">
                {" "}
                Purchase Return List{" "}
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#stockmanagement"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-cubes" />
              <span>Stock Management</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="stockmanagement"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/stockmanagement/current-stock">
                {" "}
                Current Stock{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/stockmanagement/transfer"> Transfer </NavLink>
            </li>
            <li>
              <NavLink to="/stockmanagement/adjustment"> Adjustment </NavLink>
            </li>
            <li>
              <NavLink to="/stockmanagement/transferred-list">
                {" "}
                Transferred List{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/stockmanagement/received-list">
                {" "}
                Received List{" "}
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#transfer"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-exchange-alt" />
              <span>Transfer</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="transfer"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/transfer/add-transfer"> Add Transfer </NavLink>
            </li>
            <li>
              <NavLink to="/transfer/transfer-list"> Transfer List </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#sales"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-money-bill" />
              <span>Sales</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="sales"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/sales/pos"> POS </NavLink>
            </li>
            <li>
              <NavLink to="/sales/manage-sales"> Manage Sales </NavLink>
            </li>
            <li>
              <NavLink to="/sales/sales-invoice"> Sales Invoice </NavLink>
            </li>
            <li>
              <NavLink to="/sales/return"> Return </NavLink>
            </li>
            <li>
              <NavLink to="/sales/gift-card-list"> Gift Card List </NavLink>
            </li>
            <li>
              <NavLink to="/sales/coupon-list"> Coupon List </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#expense"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-hand-holding-usd" />
              <span>Expense</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="expense"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/expense/manage-expense">Manage Expense</NavLink>
            </li>
            <li>
              <NavLink to="/expense/expense-category">
                {" "}
                Expense Category{" "}
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#asset"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-wallet" />
              <span>Asset</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="asset"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/asset/asset-list"> Asset List </NavLink>
            </li>
            <li>
              <NavLink to="/asset/asset-category"> Asset Category </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#employee"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-user-tie" />
              <span>Employee</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="employee"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/employee/add-employee"> Add Employee </NavLink>
            </li>
            <li>
              <NavLink to="/employee/employee-list"> Employee List </NavLink>
            </li>
            <li>
              <NavLink to="/employee/employee-category">
                {" "}
                Employee Category{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/employee/all-paid-salary">
                {" "}
                All Paid Salary{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/employee/advance-salary"> Advance Salary </NavLink>
            </li>
            <li>
              <NavLink to="/employee/due-salary"> Due Salary </NavLink>
            </li>
            <li>
              <NavLink to="/employee/attendance"> Attendance </NavLink>
            </li>
            <li>
              <NavLink to="/employee/pay-roll"> Pay Roll </NavLink>
            </li>
            <li>
              <NavLink to="/employee/department"> Department </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#user"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-user" />
              <span>User</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="user"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/user/all-user"> All User </NavLink>
            </li>
            <li>
              <NavLink to="/user/create-user"> Create User </NavLink>
            </li>
            <li>
              <NavLink to="/user/user-role"> User Role </NavLink>
            </li>
            <li>
              <NavLink to="/user/customer-list"> Customer List </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#accounts"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-credit-card" />
              <span>Accounts</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="accounts"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/accounts/manage-accounts">
                {" "}
                Manage Accounts{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/receive-report"> Receive Report </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/received-report">
                {" "}
                Received Report{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/receivable-report">
                {" "}
                Receivable Report{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/payment-report"> Payment Report </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/paid-report"> Paid Report </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/payable-report"> Payable Report </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/salary-report"> Salary Report </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/expense-report"> Expense Report </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/account-type"> Account Type </NavLink>
            </li>
            <li>
              <NavLink to="/accounts/account-type-list">
                {" "}
                Account Type List{" "}
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#report"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-flag" />
              <span>Report</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="report"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/report/profit-loss"> Profit / Loss </NavLink>
            </li>
            <li>
              <NavLink to="/report/product-report"> Product Report </NavLink>
            </li>
            <li>
              <NavLink to="/report/category-report"> Category Report </NavLink>
            </li>
            <li>
              <NavLink to="/report/sales-report"> Sales Report </NavLink>
            </li>
            <li>
              <NavLink to="/report/daily-sales-report">
                {" "}
                Daily Sales Report{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/report/monthly-sales-report">
                {" "}
                Monthly Sales Report{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/report/purchase-report"> Purchase Report </NavLink>
            </li>
            <li>
              <NavLink to="/report/customer-report"> Customer Report </NavLink>
            </li>
            <li>
              <NavLink to="/report/supplier-report"> Supplier Report </NavLink>
            </li>
            <li>
              <NavLink to="/report/sold-product-report">
                {" "}
                Sold Product Report{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/report/warehouse-report">
                {" "}
                Warehouse Report{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/report/warehouse-stock-chart">
                {" "}
                Warehouse Stock Chart{" "}
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu">
          <a
            href="#business"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <i className="las la-briefcase" />
              <span>Business</span>
            </div>
            <div>
              <i className="las la-angle-right sidemenu-right-icon" />
            </div>
          </a>
          <ul
            className="collapse submenu list-unstyled"
            id="business"
            data-parent="#accordionExample"
          >
            <li>
              <NavLink to="/business/business-setting">
                {" "}
                Business Setting{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/business/business-branches">
                {" "}
                Business Branches{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/business/notice-send"> Notice Send </NavLink>
            </li>
            <li>
              <NavLink to="/business/vat-tax"> VAT / TAX </NavLink>
            </li>
          </ul>
        </li>
        <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
          <div
            className="ps__thumb-x"
            tabIndex={0}
            style={{ left: 0, width: 0 }}
          />
        </div>
        <div
          className="ps__rail-y"
          style={{ top: 0, height: 758, right: "-4px" }}
        >
          <div
            className="ps__thumb-y"
            tabIndex={0}
            style={{ top: 0, height: 300 }}
          />
        </div>
      </ul>
    </nav>
  );
}
