import React, { useEffect} from "react";

export default function ReceivedList() {
  useEffect(() => {
    document.title = 'Received List';  
  }, []);

  return (
    <>
      <h1>Received List</h1>
    </>
  );
}