import React, { useEffect} from "react";

export default function AccountType() {
  useEffect(() => {
    document.title = 'Account Type';  
  }, []);

  return (
    <>
      <h1>Account Type</h1>
    </>
  );
}