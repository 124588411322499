import React, { useEffect} from "react";

export default function SuppliersPayable() {
  useEffect(() => {
    document.title = 'Suppliers Payable';  
  }, []);

  return (
    <>
      <h1>Suppliers Payable</h1>
    </>
  );
}