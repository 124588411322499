import React, { useEffect, useState } from "react";
import RequestMethods from "../../helper";
import PosProductRow from "./PosProductRow";

export default function Pos() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [brands, setBrands] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [selectedProductInput, setSelectedProductInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const requestMethods = new RequestMethods();

  function calculateGrandTotal() {
    let total = orderProducts.reduce((acc, item) => {
      return acc + item.price * item.productQuantity;
    }, 0);

    total = total + Number(tax) - Number(discount);

    setGrandTotal(total);
  }

  function showItemsCount() {
    let totalProducts = 0;
    let products = orderProducts.length;

    orderProducts.forEach((product) => {
      totalProducts += product.productQuantity;
    });

    return products + "(" + totalProducts + ")";
  }

  function showTotal() {
    let total = 0;

    orderProducts.forEach((product) => {
      total += product.productQuantity * product.price;
    });

    return formatPrice(total);
  }

  useEffect(() => {
    calculateGrandTotal();
  }, [orderProducts, tax, discount]);

  function getProducts() {
    requestMethods.getFunction("/product/product-list", setProducts);
    requestMethods.getFunction("/product/product-list", setFilterProducts);
  }

  function getCategories() {
    requestMethods.getFunction("/product/product-category", setCategories);
  }

  function getBrands() {
    requestMethods.getFunction("/product/brand", setBrands);
  }

  function getWarehouses() {
    requestMethods.getFunction("/warehouse", setWarehouses);
  }

  function filterBrand(e) {
    if (!e.target.value && !selectedCategory) {
      return setFilterProducts(products);
    }

    if (!e.target.value) {
      let filterProducts = products.filter((product) => {
        return product.category == selectedCategory;
      });

      setSelectedBrand("");

      return setFilterProducts(filterProducts);
    }

    let filterProducts = products.filter((product) => {
      if (selectedCategory) {
        return (
          product.brand == e.target.value &&
          product.category == selectedCategory
        );
      } else {
        return product.brand == e.target.value;
      }
    });

    setSelectedBrand(e.target.value);
    setFilterProducts(filterProducts);
  }

  function filterCategory(e) {
    if (!e.target.value && !selectedBrand) {
      return setFilterProducts(products);
    }

    if (!e.target.value) {
      let filterProducts = products.filter((product) => {
        return product.brand == selectedBrand;
      });

      setSelectedCategory("");

      return setFilterProducts(filterProducts);
    }

    let filterProducts = products.filter((product) => {
      if (selectedBrand) {
        return (
          product.category == e.target.value && product.brand == selectedBrand
        );
      } else {
        return product.category == e.target.value;
      }
    });

    setSelectedCategory(e.target.value);
    setFilterProducts(filterProducts);
  }

  const getSelectedProducts = (e) => {
    if (selectedProductInput) {
      let filteredProductWithInput = products.filter((product) => {
        return (
          product.name
            .toLowerCase()
            .startsWith(selectedProductInput.toLowerCase()) ||
          product.code.startsWith(selectedProductInput)
        );
      });
      setSelectedProducts(filteredProductWithInput);
    } else {
      setSelectedProducts([]);
    }
  };

  function formatPrice(grandTotal) {
    if (Number.isInteger(grandTotal)) {
      return grandTotal + ".00";
    }
  }

  const selectProduct = (id) => {
    //check if the product is already there
    let productIsPresent = orderProducts.find((product) => product.id == id);
    if (productIsPresent) {
      setSelectedProducts([]);
      setSelectedProductInput("");
      return;
    }

    //push the product to the table
    let product = products.find((product) => product.id == id);
    let productObj = {
      ...product,
      productQuantity: 1,
    };
    setOrderProducts((products) => [...products, productObj]);
    setSelectedProductInput("");
  };

  const handleOrderProductDelete = (id) => {
    //delete ordered product;
    let filteredOrderedProduct = orderProducts.filter(
      (product) => product.id != id
    );
    setOrderProducts(filteredOrderedProduct);
  };

  useEffect(() => {
    getSelectedProducts();
  }, [selectedProductInput]);

  useEffect(() => {
    document.title = "Pos";

    getProducts();
    getCategories();
    getBrands();
    getWarehouses();
  }, []);

  return (
    <>
      <section id="pos">
        <div className="left__section px-3 pt-5">
          <div className="row">
            <div className="col">
              <select className="custom-select">
                <option value="">Warehouse</option>
                {warehouses.map((warehouse) => (
                  <option value={warehouse.name}>{warehouse.name}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <select className="custom-select">
                <option selected="">Customer</option>
              </select>
            </div>
          </div>
          <div className="form-group mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search product by name/code"
              value={selectedProductInput}
              onChange={(e) => setSelectedProductInput(e.target.value)}
            />
            {!selectedProducts?.length || (
              <ul className="list-group position-absolute w-50">
                {selectedProducts.map((product) => (
                  <li
                    key={product.id}
                    className="list-group-item pointer"
                    onClick={() => selectProduct(product.id)}
                  >
                    {product.name} ( {product.code} )
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="selected__products">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Code</th>
                  <th scope="col">Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Subtotal</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {!orderProducts.length ? (
                  <tr>
                    <td colSpan="100%" className="text-center">
                      No data available in table
                    </td>
                  </tr>
                ) : (
                  orderProducts.map((product) => (
                    <PosProductRow
                      key={product.id}
                      {...{
                        setOrderProducts,
                        orderProducts,
                        product,
                        handleOrderProductDelete,
                      }}
                    />
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="leftSection__footer">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Items</th>
                  <th scope="col">Tax</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{showItemsCount()}</th>
                  <td>
                    <input
                      type="number"
                      min={0}
                      className="form-control"
                      placeholder="Tax"
                      value={tax}
                      onChange={(e) => setTax(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Discount"
                      min={0}
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </td>
                  <td>{showTotal()}</td>
                </tr>
              </tbody>
            </table>

            <div className="jumbotron">
              <h2 className="text-center">
                Grand Total {formatPrice(grandTotal)}
              </h2>
            </div>
          </div>
        </div>
        <div className="right__section px-3 pt-5">
          <div className="row">
            <div className="col">
              <select className="custom-select" onChange={filterCategory}>
                <option value="">Category</option>
                {categories.map((category) => (
                  <option value={category.name}>{category.name}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <select className="custom-select" onChange={filterBrand}>
                <option value="">Brand</option>
                {brands.map((brand) => (
                  <option value={brand.title}>{brand.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="products__container mt-4">
            <div className="row">
              {filterProducts.map((product) => (
                <div className="col-md-4 col-sm-6">
                  <div
                    className="card text-center p-3"
                    onClick={() => selectProduct(product.id)}
                  >
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${product.image}`}
                      className="card-img-top"
                    />
                    <div className="card-body p-0">
                      <h5 className="card-title">{product.name}</h5>
                      <p className="card-text">{product.code}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
