import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../helper";

//this hook gets the purchase list, calculate total purchases amount, delets the purchases, handles the purchase payment
export default function usePurchase(getPurchaseApi) {
  const [allPurchases, setAppPurchase] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [activePaymentId, setactivePaymentId] = useState([]);
  let requestMethods = new RequestMethods();

  const getPurchaseList = () => {
    requestMethods.getFunction(getPurchaseApi, setAppPurchase);
  };

  function handleDeletePurchase(ref) {
    let response = requestMethods.deleteFunction(
      `/purchase/delete-purchase/${ref}`,
      getPurchaseList
    );

    if (response) {
      toast.success("purchase successfully deleted!");
    } else {
      toast.error("purchase deleting failed!");
    }
  }

  function getPurchaseGrandTotal() {
    const findPurchase = allPurchases.find(
      (purchase) => purchase.id === activePaymentId
    );
    return findPurchase?.grand_total;
  }

  async function handlePaymentSubmit(e) {
    e.preventDefault();
    const PaymentData = {
      purchase_id: activePaymentId,
      account: e.target.account.value,
      paid: e.target.paid.value,
      payment_method: e.target.payment_method.value,
      note: e.target.note.value,
    };

    const response = await requestMethods.postFunction(
      "/purchase/add-purchase-payment",
      PaymentData
    );
    if (response.success) {
      e.target.reset();
      document.getElementById("CloseModal").click();
      getPurchaseList();
      toast.success("Purchase Payment added successfully!");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  }

  function getGrandTotal() {
    const sum = allPurchases.reduce(
      (total, purchase) => total + Number(purchase.grand_total),
      0
    );
    return sum;
  }

  function getTotalPaid() {
    const sum = allPurchases.reduce(
      (total, sale) => total + Number(sale.paid),
      0
    );
    return sum;
  }

  useEffect(() => {
    getPurchaseList();
    requestMethods.getFunction("/account/active-account", setAccounts);
  }, []);

  return {
    allPurchases,
    accounts,
    setactivePaymentId,
    handleDeletePurchase,
    getPurchaseGrandTotal,
    handlePaymentSubmit,
    getGrandTotal,
    getTotalPaid,
  };
}
