import React, { useEffect} from "react";

export default function ReceivableReport() {
  useEffect(() => {
    document.title = 'Receivable Report';  
  }, []);

  return (
    <>
      <h1>Receivable Report</h1>
    </>
  );
}