import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function TransferList() {
  const [allTransfer, setAllTransfer] = useState([]);
  const [transferProduct, setTransferProduct] = useState([]);
  const [selectedTransferDetails, setSelectedTransferDetails] = useState();
  let requestMethods = new RequestMethods();

  const getTransferList = () => {
    requestMethods.getFunction("/transfer", setAllTransfer);
  };

  function handleDeleteTransfer(id) {
    const api = `/transfer/delete-transfer/${id}`;
    const response = requestMethods.deleteFunction(api, getTransferList);
    if (response) {
      toast.success("Transfer list deleted successfully");
    } else {
      toast.error("Transfer list delete failed!");
    }
  }

  function handleTransferProductView(id) {
    const api = `/transfer/transfer-product/${id}`;
    requestMethods.getFunction(api, setTransferProduct);

    const findTransferWithId = allTransfer.find(
      (transfer) => transfer.id === id
    );
    setSelectedTransferDetails(findTransferWithId);
  }

  function grandTotal() {
    return transferProduct.reduce(function (total, obj) {
      return total + obj.cost * obj.quantity;
    }, 0);
  }

  useEffect(() => {
    getTransferList();
  }, []);

  return (
    <>
      <div className="add__button">
        <Link to="/transfer/add-transfer">
          <button type="button" className="btn">
            <i className="fa-solid fa-plus" />
            Add Transfer
          </button>
        </Link>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>

        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Date <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Reference <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Warehouse(from) <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Warehouse(To) <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Product Cost <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Product Tax <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Grand Total <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allTransfer.length ? (
              allTransfer?.map((transfer) => (
                <tr>
                  <td>{transfer.date}</td>
                  <td>{transfer.reference}</td>
                  <td>{transfer.warehouse_from}</td>
                  <td>{transfer.warehouse_to}</td>
                  <td>{transfer.product_cost}</td>
                  <td>{transfer.product_tax}</td>
                  <td>{transfer.grand_total}</td>
                  <td>
                    {transfer.status === "sent" ? (
                      <span className="badge badge-warning">
                        {transfer.status}
                      </span>
                    ) : transfer.status === "completed" ? (
                      <span className="badge badge-success">
                        {transfer.status}
                      </span>
                    ) : (
                      <span className="badge badge-danger">
                        {transfer.status}
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <Link to={`/transfer/update-transfer/${transfer.id}`}>
                            <button
                              className="dropdown-item"
                              type="button"
                              data-toggle="modal"
                              data-target="#add_unit"
                            >
                              <i className="fa-solid fa-pen-to-square mr-2" />
                              Edit
                            </button>
                          </Link>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#transfer_product_modal"
                            onClick={() =>
                              handleTransferProductView(transfer.id)
                            }
                          >
                            <i class="fa-solid fa-eye mr-2"></i>
                            View
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleDeleteTransfer(transfer.id)}
                          >
                            <i className="fa-solid fa-trash mr-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  No transfer list added
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="transfer_product_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal_big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="expense_modal">
                Transfer Details
              </h5>
              <button
                type="button"
                className="close"
                id="expenseCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-4">
                <span className=" d-flex">
                  <h6>Date:</h6> {selectedTransferDetails?.date}
                </span>
                <span className="d-flex ">
                  <h6>Reference:</h6> {selectedTransferDetails?.reference}
                </span>
                <span className="d-flex ">
                  <h6>Transfer Status:</h6> {selectedTransferDetails?.status}
                </span>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <h6>From:</h6>
                  <span className="d-block">
                    {selectedTransferDetails?.warehouse_from}
                  </span>
                  <span className="d-block">
                    {selectedTransferDetails?.wf_location}
                  </span>
                </div>
                <div className="col-6">
                  <div className="float-right">
                    <h6>To:</h6>
                    <span className="d-block">
                      {selectedTransferDetails?.warehouse_to}
                    </span>
                    <span className="d-block">
                      {selectedTransferDetails?.wt_location}
                    </span>
                  </div>
                </div>
              </div>
              <table className="table mt-3">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Unit Cost</th>
                    <th scope="col">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {transferProduct?.map((transfer) => (
                    <tr>
                      <td>{transfer.name}</td>
                      <td>{transfer.quantity}</td>
                      <td>{transfer.cost}</td>
                      <td>{transfer.cost * transfer.quantity}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">Grand Total</td>
                    <td>{grandTotal()}</td>
                  </tr>
                </tfoot>
              </table>
              <p className="mb-3 mt-4">
                <h6>Note:</h6>
                {selectedTransferDetails?.note}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
