import { Link } from "react-router-dom";
import useSales from "../../Custom hook/useSales";

export default function ManageSales() {
  const api = "/sales";
  const { allSales, handleDelete, getGrandTotal, getTotalPaid } = useSales(api);

  return (
    <>
      <div className="add__button">
        <Link to="/sales/add-sales">
          <button type="button" className="btn">
            <i className="fa-solid fa-plus" />
            Add Sale
          </button>
        </Link>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>

        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Date <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Reference <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Customer <i className="fa-solid fa-sort" />
              </th>
              {/* <th scope="col">Order Type</th>
              <th scope="col">Order Status</th> */}
              <th scope="col">Paying Method</th>
              <th scope="col">
                Grand Total <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Paid <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Due <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Invoice Payment Status <i className="fa-solid fa-sort" />
              </th>
              {/* <th scope="col">Shipping Cost</th> */}
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allSales.length ? (
              allSales.map((sales) => (
                <tr key={sales.id}>
                  <td>{sales.date}</td>
                  <td>{sales.reference}</td>
                  <td>{sales.customer}</td>
                  {/* <td>{sales.order_type}</td>
                  <td>
                    {sales.order_status === "pending" ? (
                      <span className="badge badge-danger">
                        {sales.order_status}
                      </span>
                    ) : (
                      sales.order_status
                    )}
                  </td> */}
                  <td>{sales.paying_method}</td>
                  <td>{sales.grand_total}</td>
                  <td>{sales.paid}</td>
                  <td>{sales.grand_total - sales.paid}</td>
                  <td>{sales.invoice_payment_status}</td>
                  {/* <td>{sales.shipping_cost}</td> */}
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <Link to={`/sales/edit-sales/${sales.id}`}>
                            <button
                              className="dropdown-item"
                              type="button"
                              data-toggle="modal"
                              data-target="#add_unit"
                            >
                              <i className="fa-solid fa-pen-to-square me-2" />
                              Edit
                            </button>
                          </Link>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleDelete(sales.reference)}
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11} className="text-center">
                  No sales added
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>Total</td>
              <td>{getGrandTotal()}</td>
              <td>{getTotalPaid()}</td>
              <td>{getGrandTotal() - getTotalPaid()}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
