import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ManageWarehouse() {
  const [warehouses, setWarehouses] = useState([]);
  const [editWarehouse, setEditWarehouse] = useState([]);
  const requestMethods = new RequestMethods();

  const handleDeleteWarehouse = async (id) => {
    const api = `/warehouse/delete-warehouse/${id}`;

    const response = await requestMethods.deleteFunction(api, getWarehouse);
    if (response) {
      toast.success("Warehouse deleted successfully!");
    } else {
      toast.error("Warehouse deleting failed!");
    }
  };

  const handlePostPatchWarehouse = async (e) => {
    e.preventDefault();
    const form = document.querySelector("#WarehouseForm");
    const formData = new FormData(form);

    if (editWarehouse.length) {
      const api = `/warehouse/edit-warehouse/${editWarehouse[0]?.id}`;
      const response = await requestMethods.patchFunction(api, formData);

      if (response.success) {
        toast.success("Warehouse updated!");
        form.reset();
        getWarehouse();
        document.getElementById("warehouseCloseModal").click();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0]?.field].focus();
      }
    } else {
      const api = `/warehouse/add-warehouse`;
      const response = await requestMethods.postFunction(api, formData);

      if (response.success) {
        toast.success("Warehouse added!");
        form.reset();
        getWarehouse();
        document.getElementById("warehouseCloseModal").click();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0]?.field].focus();
      }
    }
  };

  const handleEditWarehouse = async (id) => {
    const api = `/warehouse/${id}`;
    await requestMethods.getFunction(api, setEditWarehouse);
  };

  function getWarehouse() {
    requestMethods.getFunction("/warehouse", setWarehouses);
  }

  useEffect(() => {
    document.title = "Product List";
    getWarehouse();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#warehouse_modal"
          onClick={() => setEditWarehouse([])}
        >
          <i className="fa-solid fa-plus" />
          Add Warehouse
        </button>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Location</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!warehouses.length ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  No data available in table
                </td>
              </tr>
            ) : (
              warehouses.map((warehouse) => (
                <tr key={warehouse.id}>
                  <td>{warehouse.name}</td>
                  <td>{warehouse.location}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#warehouse_modal"
                            onClick={() => handleEditWarehouse(warehouse.id)}
                          >
                            <i className="fa-solid fa-pen-to-square me-2" />
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleDeleteWarehouse(warehouse.id)}
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="warehouse_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {editWarehouse.length ? "Edit Warehouse" : "Add Warehouse"}
              </h5>
              <button
                type="button"
                className="close"
                id="warehouseCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handlePostPatchWarehouse} id="WarehouseForm">
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Warehouse Name *
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="title"
                    defaultValue={editWarehouse[0]?.name}
                  />
                </div>

                <div className="mb-3">
                  <label for="warehouseAddress">Warehouse Address *</label>
                  <textarea
                    class="form-control"
                    id="warehouseAddress"
                    rows="3"
                    name="location"
                    defaultValue={editWarehouse[0]?.location || ""}
                  ></textarea>
                </div>
                <button className="btn btn-success" type="submit">
                  {editWarehouse.length ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
