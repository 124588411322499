import BarChart from "./BarChart";
import Cards from "./Cards";

export default function AccountType() {
  return (
    <>
      <Cards />
      <BarChart />
    </>
  );
}
