import React, { useEffect} from "react";

export default function VatTax() {
  useEffect(() => {
    document.title = 'Vat Tax';  
  }, []);

  return (
    <>
      <h1>Vat Tax</h1>
    </>
  );
}