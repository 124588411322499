import React, { useEffect} from "react";

export default function PayableReport() {
  useEffect(() => {
    document.title = 'Payable Report';  
  }, []);

  return (
    <>
      <h1>Payable Report</h1>
    </>
  );
}