import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import RequestMethods from "../../helper";
import "./Login.css";

export default function Auth() {
  const requestMethods = new RequestMethods();
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useState(true);

  async function handleLogin(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const response = await requestMethods.postFunction("/auth/login", formData);
    if (response.success) {
      localStorage.setItem("user", response.user_id);
      e.target.reset();
      toast.success("Admin login successfully");
      setTimeout(() => {
        navigate("/");
      }, 500);
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  }

  async function handleRegister(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const response = await requestMethods.postFunction(
      "/auth/register",
      formData
    );
    if (response.success) {
      localStorage.setItem("user", response.user_id);
      e.target.reset();
      toast.success("Admin account created");
      setTimeout(() => {
        navigate("/");
      }, 500);
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="auth_form">
        <div className="auth_form_content_container">
          <h3 className="inventory_logo font-weight-bold text-center mb-4">
            Digitalhero
          </h3>
          {isLogin ? (
            <form onSubmit={handleLogin}>
              <div className="form-outline mb-3">
                <label className="form-label" for="username">
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Enter your username"
                  className="form-control"
                />
              </div>

              <div className="form-outline mb-3">
                <label className="form-label" for="password">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  className="form-control"
                />
              </div>
              {/* 
            <div className="row mb-4">
              <div className="col-md-6 d-flex justify-content-center">
                <a href="#!">Forgot password?</a>
              </div>
            </div> */}

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Sign in
              </button>

              <div className="text-center">
                <p>
                  Not a member?{" "}
                  <span
                    role="button"
                    style={{ color: "#0056b3" }}
                    onClick={() => setIsLogin(false)}
                  >
                    Register
                  </span>
                </p>
              </div>
            </form>
          ) : (
            <form onSubmit={handleRegister}>
              <div className="form-outline mb-3">
                <label className="form-label" for="username">
                  Username
                </label>
                <input
                  placeholder="Enter a username"
                  type="text"
                  id="username"
                  name="username"
                  className="form-control"
                />
              </div>
              <div className="form-outline mb-3">
                <label className="form-label" for="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  name="email"
                  className="form-control"
                />
              </div>

              <div className="form-outline mb-3">
                <label className="form-label" for="password">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                />
              </div>

              <div className="form-outline mb-4">
                <label className="form-label" for="repeat_password">
                  Repeat password
                </label>
                <input
                  type="password"
                  name="repeat_password"
                  id="repeat_password"
                  className="form-control"
                />
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-3">
                Sign Up
              </button>

              <div className="text-center">
                <p>
                  Already has a account?{" "}
                  <span
                    role="button"
                    style={{ color: "#0056b3" }}
                    onClick={() => setIsLogin(true)}
                  >
                    sign in
                  </span>
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
