import React, { useEffect, useState } from "react";
import RequestMethods from "../../helper";

export default function SalesReport() {
  let requestMethods = new RequestMethods();
  const [salesReportList, setSalesReportList] = useState([]);

  function getSalesReport() {
    requestMethods.getFunction("sales/sales-report", setSalesReportList);
  }

  useEffect(() => {
    getSalesReport();
  }, []);

  return (
    <>
      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Code</th>
              <th scope="col">
                Product Name <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Product Quantity <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Customer Name <i className="fa-solid fa-sort" />
              </th>
            </tr>
          </thead>
          <tbody>
            {!salesReportList.length ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  No sales report list available in table
                </td>
              </tr>
            ) : (
              salesReportList.map((saleReport) => (
                <tr key={saleReport.id}>
                  <td>{saleReport.date}</td>
                  <td>{saleReport.code}</td>
                  <td>{saleReport.product_name}</td>
                  <td>{saleReport.quantity}</td>
                  <td>{saleReport.customer_name}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
