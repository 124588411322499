import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ProductCategory() {
  const [categories, setCategories] = useState([]);
  const [editCategory, seteditCategory] = useState([]);
  const requestMethods = new RequestMethods();

  const submitHandler = async (e) => {
    e.preventDefault();
    let form = document.getElementById("productCategoryForm");
    let formData = new FormData(form);
    const categoryCloseModal = document.getElementById("CategoryCloseModal");

    if (editCategory.length) {
      const api = `/product/edit-category/${editCategory[0].id}`;
      const response = await requestMethods.patchFunction(api, formData);
      if (response) {
        toast.success("Category updated successfully!");
        categoryCloseModal.click();
        form.reset();
        seteditCategory([]);
        getProductCategory();
      }
    } else {
      const api = "/product/add-category";
      const response = await requestMethods.postFunction(api, formData);
      if (response.success) {
        toast.success("Category added successfully!");
        categoryCloseModal.click();
        form.reset();
        getProductCategory();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
          e.target[err.field].focus();
        });
      }
    }
  };

  const handleDelete = (id) => {
    const api = `/product/delete-category/${id}`;
    const response = requestMethods.deleteFunction(api, getProductCategory);
    if (response) {
      toast.success("Category deleted successfully!");
    }
  };

  const getProductCategory = () => {
    const api = "/product/product-category";
    requestMethods.getFunction(api, setCategories);
  };

  const getSingleProductCategory = (id) => {
    const api = `/product/product-category/${id}`;
    requestMethods.getFunction(api, seteditCategory);
  };

  useEffect(() => {
    getProductCategory();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#category_modal"
          onClick={() => seteditCategory([])}
        >
          <i className="fa-solid fa-plus" />
          Add Category
        </button>
      </div>
      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Category <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Number of Product <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Stock Quantity <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Stock Worth(Price/Cost) <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {categories.length ? (
              categories.map((cat) => (
                <tr key={cat.id}>
                  <td>{cat.name}</td>
                  <td>0</td>
                  <td>0</td>
                  <td>N/A</td>
                  <td>active</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#category_modal"
                            onClick={() => getSingleProductCategory(cat.id)}
                          >
                            <i className="fa-solid fa-pen-to-square me-2" />
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => handleDelete(cat.id)}
                            className="dropdown-item"
                            type="button"
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="100%" className="text-center">
                  No data available in table
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="category_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {editCategory.length ? "Edit Category" : "Add Category"}
              </h5>
              <button
                type="button"
                className="close"
                id="CategoryCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={submitHandler} id="productCategoryForm">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    defaultValue={editCategory[0]?.name}
                    id="name"
                    required
                  />
                </div>

                <button className="btn btn-success" type="submit">
                  {editCategory.length ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
