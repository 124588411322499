import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestMethods, { setCheckBoxToFormData } from "../../helper";
import { useNavigate, useParams } from "react-router-dom";

export default function EditSuppliers() {
  const [image, setImage] = useState(null);
  const [editSupplier, setEditSupplier] = useState([]);
  const requestMethods = new RequestMethods();
  let { id } = useParams();
  const navigate = useNavigate();

  async function getSuppliers() {
    let api = `/supplier/${id}`;
    await requestMethods.getFunction(api, setEditSupplier);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const productSupplierForm = document.getElementById(
      "productSupplierFormEdit"
    );
    const formData = new FormData(productSupplierForm);
    setCheckBoxToFormData(e, formData, "status");
    setCheckBoxToFormData(e, formData, "multiplewarehouse");
    formData.append("image", image);

    const api = `/supplier/edit-supplier/${id}`;
    let response = await requestMethods.patchFunction(api, formData);
    if (response.success) {
      productSupplierForm.reset();
      setImage(null);
      toast.success("supplier updated successfully");
      navigate("/suppliers/suppliers-list");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  return (
    <>
      <form
        className="my-3"
        id="productSupplierFormEdit"
        onSubmit={handleSubmit}
      >
        <div className="row gy-3">
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="suppliername" className="form-label mb-2">
                Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="suppliername"
                name="name"
                defaultValue={editSupplier[0]?.name}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="image">Image</label>
              <input
                type="file"
                name="image"
                id="image"
                className="form-control"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="companyname" className="form-label mb-2">
                Company Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="companyname"
                name="companyname"
                defaultValue={editSupplier[0]?.company_name}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="vatnumber" className="form-label mb-2">
                VAT Number
              </label>
              <input
                type="text"
                className="form-control"
                id="vatnumber"
                name="vatnumber"
                defaultValue={editSupplier[0]?.vat_number}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="Email" className="form-label mb-2">
                Email *
              </label>
              <input
                type="email"
                className="form-control"
                id="Email"
                name="email"
                defaultValue={editSupplier[0]?.email}
                placeholder="example@gmail.com"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="phonenumber" className="form-label mb-2">
                Phone Number *
              </label>
              <input
                type="tel"
                className="form-control"
                id="phonenumber"
                name="phonenumber"
                pattern="[0-9]{11}"
                placeholder="01533234087"
                defaultValue={editSupplier[0]?.phone_number}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="Address" className="form-label mb-2">
                Address *
              </label>
              <input
                type="text"
                className="form-control"
                id="Address"
                name="address"
                defaultValue={editSupplier[0]?.address}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="city" className="form-label mb-2">
                City *
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                defaultValue={editSupplier[0]?.city}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="state" className="form-label mb-2">
                State
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                name="state"
                defaultValue={editSupplier[0]?.state}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="postalcode" className="form-label mb-2">
                Postal Code
              </label>
              <input
                type="number"
                className="form-control"
                id="postalcode"
                name="postalcode"
                defaultValue={editSupplier[0]?.postal_code}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="country" className="form-label mb-2">
                Country
              </label>
              <input
                type="text"
                className="form-control"
                id="country"
                name="country"
                defaultValue={editSupplier[0]?.country}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="advance" className="form-label mb-2">
                Advance
              </label>
              <input
                type="number"
                className="form-control"
                id="advance"
                name="advance"
                defaultValue={editSupplier[0]?.advance}
              />
            </div>
          </div>

          <div className="col-12 px-3 d-flex align-items-center">
            <div className="custom-control custom-switch mb-2 mt-3 d-flex align-items-center">
              <input
                className="custom-control-input form-check-input mt-0 me-2"
                id="status"
                type="checkbox"
                name="status"
                defaultChecked={editSupplier[0]?.status}
                aria-label="Checkbox for following text input"
              />

              <label className="custom-control-label" htmlFor="status">
                Status
              </label>
            </div>
          </div>

          <div className="col-12 px-3 d-flex align-items-center">
            <div className="custom-control custom-switch mb-5 mt-3 d-flex align-items-center">
              <input
                className="custom-control-input form-check-input mt-0 me-2"
                id="multiplewarehouse"
                type="checkbox"
                name="multiplewarehouse"
                defaultChecked={editSupplier[0]?.multiple_warehouse}
                aria-label="Checkbox for following text input"
              />
              <label
                htmlFor="multiplewarehouse"
                className="custom-control-label font-weight-bold"
              >
                Select Multiple Warehouse
              </label>
            </div>
          </div>
        </div>
        <button className="btn btn-success mb-5" type="submit">
          Updated Supplier
        </button>
      </form>
    </>
  );
}
