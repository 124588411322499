import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ExpenseCategory() {
  const [allExpenseCategory, setAllExpenseCategory] = useState([]);
  const [editExpenseCategory, setEditExpenseCategory] = useState([]);
  const codeFieldRef = useRef(null);

  let requestMethods = new RequestMethods();

  const generateCode = () => {
    const code = Math.random().toString().substr(2, 6);
    codeFieldRef.current.value = code;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (editExpenseCategory.length) {
      const api = `/expense/edit-category/${editExpenseCategory[0].id}`;
      const response = await requestMethods.patchFunction(api, formData);

      if (response.success) {
        toast.success("Expense category updated successfully!");
        e.target.reset();
        getExpenseCategory();
        document.querySelector("#expenseCatCloseModal").click();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0]?.field].focus();
      }
    } else {
      const api = "/expense/add-category";
      const response = await requestMethods.postFunction(api, formData);

      if (response.success) {
        toast.success("Expense category added successfully!");
        e.target.reset();
        getExpenseCategory();
        document.querySelector("#expenseCatCloseModal").click();
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
        });
        e.target[response.error[0]?.field].focus();
      }
    }
  };

  const handleDeleteExCategory = (id) => {
    const api = `/expense/delete-category/${id}`;
    const response = requestMethods.deleteFunction(api, getExpenseCategory);
    if (response) {
      toast.success("Expense category deleted successfully!");
    } else {
      toast.error("Expense category deleting failed!");
    }
  };

  const getExpenseCategory = () => {
    const api = "/expense/category";
    requestMethods.getFunction(api, setAllExpenseCategory);
  };

  const getSingleExpenseCategory = (id) => {
    const api = `/expense/category/${id}`;
    requestMethods.getFunction(api, setEditExpenseCategory);
  };

  useEffect(() => {
    getExpenseCategory();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#expense_category_modal"
          onClick={() => setEditExpenseCategory([])}
        >
          <i className="fa-solid fa-plus" />
          Add Expense Category
        </button>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>

        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div>
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Code <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Name <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allExpenseCategory?.map((expenseCategory) => (
              <tr key={expenseCategory.id}>
                <td>{expenseCategory.code}</td>
                <td>{expenseCategory.name}</td>
                <td>
                  <div className="dropdown dropstart">
                    <button
                      className="btn btn-warning dropdown-toggle btn-sm"
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenu2"
                    >
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          data-toggle="modal"
                          data-target="#expense_category_modal"
                          onClick={() =>
                            getSingleExpenseCategory(expenseCategory.id)
                          }
                        >
                          <i className="fa-solid fa-pen-to-square me-2" />
                          Edit
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() =>
                            handleDeleteExCategory(expenseCategory.id)
                          }
                        >
                          <i className="fa-solid fa-trash me-2" />
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="expense_category_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="expense_modal">
                {editExpenseCategory.length
                  ? "Edit Expense Category"
                  : "Add Expense Category"}
              </h5>
              <button
                type="button"
                className="close"
                id="expenseCatCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p class="italic">
                <small>
                  The field labels marked with * are required input fields.
                </small>
              </p>
              <form onSubmit={handleSubmit} id="productBrandForm">
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label htmlFor="code" className="form-label mb-2">
                      Code *
                    </label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Type expense category code..."
                        name="code"
                        id="code"
                        ref={codeFieldRef}
                        defaultValue={editExpenseCategory[0]?.code}
                        type="text"
                      />
                      <div class="input-group-append">
                        <button
                          id="genbutton"
                          type="button"
                          class="btn btn-default"
                          onClick={generateCode}
                          style={{
                            border: "1px solid #8dbf42",
                            color: "#8dbf42",
                          }}
                        >
                          Generate
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 col-md-12">
                    <label htmlFor="name" className="form-label">
                      Name *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      defaultValue={editExpenseCategory[0]?.name}
                      name="name"
                    />
                  </div>
                </div>
                <button className="btn btn-success" type="submit">
                  {editExpenseCategory.length ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
