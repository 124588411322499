import React, { useEffect} from "react";

export default function BusinessSetting() {
  useEffect(() => {
    document.title = 'Business Setting';  
  }, []);

  return (
    <>
      <h1>Business Setting</h1>
    </>
  );
}