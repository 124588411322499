import React, { useEffect} from "react";

export default function CurrentStock() {
  useEffect(() => {
    document.title = 'Current Stock';  
  }, []);

  return (
    <>
      <h1>Current Stock</h1>
    </>
  );
}