import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function UpdateQuotation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editQuotation, setEditQuotation] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [allWarehouse, setAllWarehouse] = useState([]);
  const [file, setFile] = useState(null);

  let requestMethods = new RequestMethods();

  const handleFormUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("file", file);

    const api = `/quotation/edit-quotation/${id}`;
    const response = await requestMethods.patchFunction(api, formData);
    if (response.success) {
      toast.success("Quotation updated successfully");
      navigate("/quotation/manage-quotation");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    const api = `/quotation/${id}`;
    requestMethods.getFunction(api, setEditQuotation);

    let supplierAPi = "/supplier";
    let customerAPi = "/customer";
    let warehouseAPi = "/warehouse";
    requestMethods.getFunction(supplierAPi, setAllSuppliers);
    requestMethods.getFunction(customerAPi, setAllCustomers);
    requestMethods.getFunction(warehouseAPi, setAllWarehouse);
  }, []);

  return (
    <div className="p-4">
      <h4 className="mb-3 mt-3">Update Quotation</h4>
      <div>
        <p className="mb-4 mt-3 italic">
          <small>
            The field labels marked with * are required input fields.
          </small>
        </p>
        <form id="purchase-form" onSubmit={handleFormUpdate}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="Supplier" className="form-label mb-2">
                  Supplier *
                </label>
                <select className="custom-select" id="Supplier" name="supplier">
                  <option value="">Select supplier...</option>
                  {allSuppliers.map((supplier) => (
                    <option
                      selected={
                        editQuotation[0]?.supplier == supplier.id
                          ? "selected"
                          : ""
                      }
                      key={supplier.id}
                      value={supplier.id}
                    >
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="customer" className="form-label mb-2">
                  Customer *
                </label>
                <select className="custom-select" id="customer" name="customer">
                  <option value="">select customer ...</option>
                  {allCustomers?.map((customer) => (
                    <option
                      selected={
                        editQuotation[0]?.customer == customer.id
                          ? "selected"
                          : ""
                      }
                      key={customer.id}
                      value={customer.id}
                    >
                      {customer.name} ({customer.phone_number})
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="warehouse" className="form-label mb-2">
                  Warehouse *
                </label>
                <select
                  className="custom-select"
                  id="warehouse"
                  name="warehouse"
                >
                  <option value="">select warehouse ...</option>
                  {allWarehouse?.map((warehouse) => (
                    <option
                      selected={
                        editQuotation[0]?.warehouse == warehouse.id
                          ? "selected"
                          : ""
                      }
                      key={warehouse.id}
                      value={warehouse.id}
                    >
                      {warehouse.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-4">
              <div className="mb-2">
                <label htmlFor="status" className="form-label mb-2">
                  Status
                </label>
                <select
                  className="custom-select"
                  id="status"
                  name="status"
                  aria-label="Default select example"
                >
                  <option
                    value={0}
                    selected={editQuotation[0]?.status == 0 && "selected"}
                  >
                    Pending
                  </option>
                  <option
                    value={1}
                    selected={editQuotation[0]?.status == 1 && "selected"}
                  >
                    Sent
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-2">
                <label htmlFor="file">
                  Attach Document{" "}
                  <i
                    className="fa-regular fa-circle-question"
                    data-toggle="tooltip"
                    title="Only png, jpeg, pdf, txt file is supported!"
                  ></i>
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="form-control"
                  onChange={(e) => setFile(e.target.files[0])}
                  accept="image/png, image/jpeg, application/pdf, application/txt"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="mb-2">
                <label htmlFor="note" className="form-label mb-2">
                  Note
                </label>
                <textarea
                  className="form-control"
                  id="note"
                  name="note"
                  rows="4"
                  cols="50"
                  defaultValue={editQuotation[0]?.note || ""}
                ></textarea>
              </div>
            </div>
          </div>

          <button className="btn btn-success mt-3" type="submit">
            Update
          </button>
        </form>
      </div>
    </div>
  );
}
