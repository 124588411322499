import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods, { setCheckBoxToFormData } from "../../helper";

export default function AddSuppliers() {
  const [image, setImage] = useState(null);
  const requestMethods = new RequestMethods();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const productSupplierForm = document.getElementById("productSupplierForm");
    const formData = new FormData(productSupplierForm);
    setCheckBoxToFormData(e, formData, "status");
    formData.append("image", image);

    const api = "/supplier/add-supplier";
    let response = await requestMethods.postFunction(api, formData);
    if (response.success) {
      toast.success("Supplier added successfully!");
      productSupplierForm.reset();
      setImage(null);
      navigate("/suppliers/suppliers-list");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  return (
    <>
      <form className="my-1" id="productSupplierForm" onSubmit={handleSubmit}>
        <div className="row gy-3">
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="suppliername" className="form-label mb-2">
                Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="suppliername"
                name="name"
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="image">Image</label>
              <input
                type="file"
                name="image"
                id="image"
                className="form-control"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="companyname" className="form-label mb-2">
                Company Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="companyname"
                name="companyname"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="vatnumber" className="form-label mb-2">
                VAT Number
              </label>
              <input
                type="text"
                className="form-control"
                id="vatnumber"
                name="vatnumber"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="Email" className="form-label mb-2">
                Email *
              </label>
              <input
                type="email"
                className="form-control"
                id="Email"
                name="email"
                placeholder="example@gmail.com"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="phonenumber" className="form-label mb-2">
                Phone Number *
              </label>
              <input
                type="tel"
                className="form-control"
                id="phonenumber"
                name="phonenumber"
                pattern="[0-9]{11}"
                placeholder="01533234087"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="Address" className="form-label mb-2">
                Address *
              </label>
              <input
                type="text"
                className="form-control"
                id="Address"
                name="address"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="city" className="form-label mb-2">
                City *
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="state" className="form-label mb-2">
                State
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                name="state"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="postalcode" className="form-label mb-2">
                Postal Code
              </label>
              <input
                type="number"
                className="form-control"
                id="postalcode"
                name="postalcode"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-3">
              <label htmlFor="country" className="form-label mb-2">
                Country
              </label>
              <input
                type="text"
                className="form-control"
                id="country"
                name="country"
              />
            </div>
          </div>

          <div className="col-12 px-4 d-flex align-items-center">
            <div className="mb-2 mt-3 d-flex align-items-center">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input mt-0"
                  id="status"
                  name="status"
                />
                <label className="custom-control-label" htmlFor="status">
                  Status
                </label>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-success mb-4" type="submit">
          Add Supplier
        </button>
      </form>
    </>
  );
}
