import React, { useRef, useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Editor } from "@tinymce/tinymce-react";
import RequestMethods, { setCheckBoxToFormData } from "../../helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const fileTypes = ["JPG", "PNG"];

export default function AddProduct() {
  const editorRef = useRef(null);
  const requestMethods = new RequestMethods();
  const navigate = useNavigate();
  const [brands, setbrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setcategories] = useState([]);
  const [productUnits, setProductUnits] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);

  const [file, setFile] = useState(null);

  const handleImageChange = (file) => {
    setFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const productForm = document.getElementById("productForm");
    let formData = new FormData(productForm);

    formData.append("picture", file);
    formData.append("details", editorRef.current.getContent());
    setCheckBoxToFormData(e, formData, "isNotForSale");

    const api = "/product/add-product";
    const response = await requestMethods.postFunction(api, formData);
    if (response.success) {
      productForm.reset();
      setFile(null);
      toast.success("Product added successfully!");
      navigate("/product/product-list");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
        e.target[err.field].focus();
      });
    }
  };

  useEffect(() => {
    const getAllSupplier = "/supplier";
    const getAllBrand = "/product/brand";
    const getAllcategories = "/product/product-category";
    const getAllUnit = "/product/product-unit";

    const getAllSize = "/product/size";
    const getAllColor = "/product/color";

    requestMethods.getFunction(getAllSupplier, setSuppliers);
    requestMethods.getFunction(getAllBrand, setbrands);
    requestMethods.getFunction(getAllcategories, setcategories);
    requestMethods.getFunction(getAllUnit, setProductUnits);
    requestMethods.getFunction(getAllSize, setSizes);
    requestMethods.getFunction(getAllColor, setColors);
  }, []);

  return (
    <>
      <form className="my-3" id="productForm" onSubmit={handleSubmit}>
        <div className="row gy-3">
          {/* <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="producttype" className="form-label mb-2">
                Product Type *
              </label>
              <select
                className="custom-select"
                id="producttype"
                name="type"
                aria-label="Default select example"
              >
                <option value="">Select product type</option>
                <option value="standard">Standard</option>
                <option value="combo">Combo</option>
                <option value="digital">Digital</option>
              </select>
            </div>
          </div> */}
          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="name" className="form-label mb-2">
                Product Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="productcode" className="form-label mb-2">
                Product Code *
              </label>
              <input
                type="text"
                className="form-control"
                id="productcode"
                name="code"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="brand" className="form-label mb-2">
                Brand *
              </label>
              <select
                className="custom-select"
                id="brand"
                name="brand"
                aria-label="Default select example"
              >
                <option value="">Select Brand</option>
                {brands.map(({ title, id }) => (
                  <option value={id} key={id}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="category" className="form-label mb-2">
                Category *
              </label>
              <select
                className="custom-select"
                id="category"
                name="category"
                aria-label="Default select example"
              >
                <option value="">Select Category</option>
                {categories.map(({ name, id }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="size" className="form-label mb-2">
                Size
              </label>
              <select
                className="custom-select"
                id="size"
                name="size"
                aria-label="Default select example"
              >
                <option value="">Select Size</option>
                {sizes.map(({ name, id }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="color" className="form-label mb-2">
                Color
              </label>
              <select
                className="custom-select"
                id="color"
                name="color"
                aria-label="Default select example"
              >
                <option value="">Select Color</option>
                {colors.map(({ code, id }) => (
                  <option value={id} key={id} style={{ background: code }}>
                    {code}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="productunit" className="form-label mb-2">
                Product Unit *
              </label>
              <select
                className="custom-select"
                id="productunit"
                name="productUnit"
                aria-label="Default select example"
              >
                <option value="">Select Product Unit</option>
                {productUnits.map(({ name, id }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="saleunit" className="form-label mb-2">
                Sale Unit *
              </label>
              <select
                className="custom-select"
                id="saleunit"
                name="saleUnit"
                aria-label="Default select example"
              >
                <option value="">Selecte sales unit</option>
                {productUnits.map(({ name, id }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="purchaseunit" className="form-label mb-2">
                Purchase Unit *
              </label>
              <select
                className="custom-select"
                id="purchaseunit"
                name="purchaseUnit"
                aria-label="Default select example"
              >
                <option value="">Select purchase unit</option>
                {productUnits.map(({ name, id }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="productcost" className="form-label mb-2">
                Product Cost *
              </label>
              <input
                type="number"
                className="form-control"
                id="productcost"
                name="cost"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="productprice" className="form-label mb-2">
                Product Price *
              </label>
              <input
                type="number"
                className="form-control"
                id="productprice"
                name="price"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="mb-3">
              <label htmlFor="supplier" className="form-label mb-2">
                Supplier *
              </label>
              <select
                className="custom-select"
                id="supplier"
                name="supplier"
                aria-label="Default select example"
              >
                <option value="">Select Supplier</option>
                {suppliers.map(({ name, id }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12">
            <label htmlFor="">Product Image</label>
            <div className="text-center text-muted rounded mt-2">
              <FileUploader
                handleChange={handleImageChange}
                types={fileTypes}
              />
            </div>
          </div>
          <div className="mb-3 col-12">
            <label htmlFor="productdetails" className="form-label">
              Product Details
            </label>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue=""
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>

          <div className="col-12 ml-2 d-flex align-items-center">
            <div className="custom-control custom-switch mb-5 d-flex align-items-center">
              <input
                type="checkbox"
                name="isNotForSale"
                className="custom-control-input"
                id="isNotForSale"
              />
              <label className="custom-control-label" htmlFor="isNotForSale">
                Not For Sale
              </label>
            </div>
          </div>
        </div>
        <button className="btn btn-success mb-5" type="submit">
          Add Product
        </button>
      </form>
    </>
  );
}
