import { useState, useEffect } from "react";
import RequestMethods from "../../helper";
import "./style.css";

export default function Cards() {
  const requestMethods = new RequestMethods();
  const [totalSales, setTotalSales] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [purchase, setPurchase] = useState(0);

  useEffect(() => {
    requestMethods.getFunction("/dashboard/revenue", setRevenue);
    requestMethods.getFunction("/dashboard/total-sales", setTotalSales);
    requestMethods.getFunction("/dashboard/total-purchase", setPurchase);
  }, []);

  return (
    <div className="row">
      <div className="col-md-4 col-lg-3 mb-3">
        <div class="card">
          <div class="card-body text-center">
            <i class="fa-solid fa-chart-line dashboard_card_icon"></i>
            <h5 class="card-title dashboard_card_heading">Revenue</h5>
            <p class="card-text display-4  dashboard_card_value">
              {revenue[0]?.total || "0.00"}
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-lg-3 mb-3">
        <div class="card">
          <div class="card-body text-center">
            <i class="fa-regular fa-credit-card dashboard_card_icon"></i>
            <h5 class="card-title dashboard_card_heading">Total Purchase</h5>
            <p class="card-text display-4 dashboard_card_value">
              {purchase[0]?.total || "0.00"}
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-lg-3 mb-3">
        <div class="card">
          <div class="card-body text-center">
            <i class="fa-solid fa-money-bill-wave dashboard_card_icon"></i>
            <h5 class="card-title dashboard_card_heading">Total Sales</h5>
            <p class="card-text display-4 dashboard_card_value">
              {totalSales[0]?.total || "0.00"}
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-lg-3 mb-3">
        <div class="card">
          <div class="card-body text-center">
            <i class="fa-solid fa-piggy-bank dashboard_card_icon"></i>
            <h5 class="card-title dashboard_card_heading">Profit</h5>
            <p class="card-text display-4 dashboard_card_value">
              {revenue[0]?.total - purchase[0]?.total || "0.00"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
