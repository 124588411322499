import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function UpdateSales() {
  let requestMethods = new RequestMethods();
  const [editSale, setEditSale] = useState([]);
  const [allCustomer, setAllCustomer] = useState([]);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleFormUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("document", file);

    const salePatchtApi = `/sales/edit-sales/${id}`;
    const response = await requestMethods.patchFunction(
      salePatchtApi,
      formData
    );
    if (response.success) {
      e.target.reset();
      setFile();
      navigate("/sales/manage-sales");
      toast.success("Sales updated successfully!");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    let singleSaleApi = `/sales/${id}`;
    requestMethods.getFunction(singleSaleApi, setEditSale);

    requestMethods.getFunction("/customer", setAllCustomer);
  }, []);

  return (
    <div className="p-4">
      <h4 className="mb-3 mt-3">Update Sales</h4>
      <div>
        <p className="mb-4 mt-3 italic">
          <small>
            The field labels marked with * are required input fields.
          </small>
        </p>
        <form id="purchase-form" onSubmit={handleFormUpdate}>
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-2">
                <h5>Reference</h5>
                <p className="font-weight-bold" style={{ fontSize: "1.5rem" }}>
                  {editSale[0]?.reference}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="customer" className="form-label mb-2">
                  Customer *
                </label>
                <select className="custom-select" id="customer" name="customer">
                  <option value="">Select customer...</option>
                  {allCustomer?.map((customer) => (
                    <option
                      selected={
                        editSale[0]?.customer_id === customer.id && "selected"
                      }
                      value={customer.id}
                    >
                      {customer.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="document">
                  Attach Document{" "}
                  <i
                    className="fa-regular fa-circle-question"
                    data-toggle="tooltip"
                    title="Only png, jpeg, pdf, txt file is supported!"
                  ></i>
                </label>
                <input
                  type="file"
                  name="document"
                  id="document"
                  className="form-control"
                  onChange={(e) => setFile(e.target.files[0])}
                  accept="image/png, image/jpeg, application/pdf, application/txt"
                />
              </div>
            </div>
            {/* <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="order_status" className="form-label mb-2">
                  Order Status
                </label>
                <select
                  className="custom-select"
                  id="order_status"
                  name="order_status"
                  aria-label="Default select example"
                >
                  <option
                    value="pending"
                    selected={
                      editSale[0]?.order_status === "pending" && "selected"
                    }
                  >
                    Pending
                  </option>
                  <option
                    value="completed"
                    selected={
                      editSale[0]?.order_status === "completed" && "selected"
                    }
                  >
                    Completed
                  </option>
                </select>
              </div>
            </div> */}
            {/* <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="order_type" className="form-label mb-2">
                  Order Type
                </label>
                <select
                  className="custom-select"
                  id="order_type"
                  name="order_type"
                  aria-label="Default select example"
                >
                  <option
                    value="preorder"
                    selected={
                      editSale[0]?.order_type === "preorder" && "selected"
                    }
                  >
                    Pre order
                  </option>
                  <option
                    value="instant sale"
                    selected={
                      editSale[0]?.order_type === "instant sale" && "selected"
                    }
                  >
                    Instant Sale
                  </option>
                  <option
                    value="quatation"
                    selected={
                      editSale[0]?.order_type === "quatation" && "selected"
                    }
                  >
                    Quatation
                  </option>
                </select>
              </div>
            </div> */}
            {/* <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="shipping_cost" className="form-label mb-2">
                  Shipping
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="shipping_cost"
                  name="shipping_cost"
                  defaultValue={editSale[0]?.shipping_cost}
                  min={0}
                />
              </div>
            </div> */}
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="saleNote" className="form-label mb-2">
                  Sale Note
                </label>
                <textarea
                  className="form-control"
                  id="saleNote"
                  name="saleNote"
                  rows="4"
                  cols="50"
                  defaultValue={editSale[0]?.sale_note || ""}
                ></textarea>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-2">
                <label htmlFor="staffNote" className="form-label mb-2">
                  Staff Note
                </label>
                <textarea
                  className="form-control"
                  id="staffNote"
                  name="staffNote"
                  rows="4"
                  cols="50"
                  defaultValue={editSale[0]?.staff_note || ""}
                ></textarea>
              </div>
            </div>
          </div>

          <button className="btn btn-success mt-3" type="submit">
            Update
          </button>
        </form>
      </div>
    </div>
  );
}
