import React, { useEffect } from "react";

export default function PrintBarcode() {
  useEffect(() => {
    document.title = "Print Barcode";
  }, []);

  return (
    <>
      <h4 className="mb-5">Print Barcode</h4>
      <label htmlFor="" className="form-label fw-bold">
        Add Product
      </label>
      <div className="input-group mb-3 w-50">
        <span className="input-group-text" id="basic-addon1">
          <i className="fa-solid fa-barcode" />
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Please type product code and select"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Code</th>
              <th scope="col">Quantity</th>
              <th scope="col">
                <i className="fa-regular fa-trash-can" style={{ opacity: 1 }} />
              </th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td colspan="100%" class="text-center">No data available in table</td>
            </tr> */}
          </tbody>
        </table>
      </div>
      <div className="input-group my-5 pl-4">
        <div className="d-flex align-items-center">
          <span className="fw-bold">Print : &nbsp; &nbsp;</span>
          <input
            className="form-check-input mt-0 me-2"
            type="checkbox"
            defaultValue=""
            aria-label="Checkbox for following text input"
            id="productname"
            defaultChecked=""
          />
          <label htmlFor="productname">Product Name</label>
          <input
            className="form-check-input ms-3 mt-0 me-2"
            type="checkbox"
            defaultValue=""
            aria-label="Checkbox for following text input"
            id="productprice"
            defaultChecked=""
          />
          <label htmlFor="productprice">Price</label>
          <input
            className="form-check-input ms-3 mt-0 me-2"
            type="checkbox"
            defaultValue=""
            aria-label="Checkbox for following text input"
            id="promotionalprice"
          />
          <label htmlFor="promotionalprice">Promotional Price</label>
        </div>
      </div>
      <div className="my-3">
        <label className="fw-bold mb-2 d-block">Paper Size</label>
        <select
          className="custom-select w-50"
          aria-label=".form-select-sm example"
        >
          <option value={25} selected="">
            36mm (1.4 inch)
          </option>
          <option value={50}>12mm (1 inch)</option>
        </select>
      </div>
      <button className="btn btn-success" type="submit">
        Print
      </button>
    </>
  );
}
