import React, { useEffect} from "react";

export default function Transfer() {
  useEffect(() => {
    document.title = 'Transfer';  
  }, []);

  return (
    <>
      <h1>Transfer</h1>
    </>
  );
}