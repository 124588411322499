import React, { useState, useEffect } from "react";
import RequestMethods from "../../helper";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

export default function UpdateEmployee() {
  const requestMethods = new RequestMethods();
  const navigate = useNavigate();
  const { id } = useParams();
  const [editEmployee, setEditEmployee] = useState([]);
  const [employCategories, setemployCategories] = useState([]);
  const [employDepartments, setemployDepartments] = useState([]);

  const handleEdit = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    const api = `/employe/edit-employee/${id}`;
    const response = await requestMethods.patchFunction(api, formData);
    if (response.success) {
      e.target.reset();
      toast.success("Employee updated successfully!");
      navigate("/employee/employee-list");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    const getSingleEmploye = `/employe/${id}`;
    const getAllDept = "/employe/department";
    const getAllCat = "/employe/category";

    requestMethods.getFunction(getSingleEmploye, setEditEmployee);
    requestMethods.getFunction(getAllDept, setemployDepartments);
    requestMethods.getFunction(getAllCat, setemployCategories);
  }, []);

  return (
    <>
      <form className="my-3" id="productForm" onSubmit={handleEdit}>
        <div className="row gy-3">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="name" className="form-label mb-2">
                Employee Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                defaultValue={editEmployee[0]?.name}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="phone" className="form-label mb-2">
                Phone number *
              </label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                pattern="[0-9]{11}"
                placeholder="example: 01533234087"
                defaultValue={editEmployee[0]?.phone}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="email" className="form-label mb-2">
                Email *
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="example@gmail.com"
                defaultValue={editEmployee[0]?.email}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="category" className="form-label mb-2">
                Employee Category *
              </label>
              <select
                className="custom-select"
                id="category"
                name="category"
                aria-label="Default select example"
              >
                <option value="">Select category</option>
                {employCategories?.map((category) => (
                  <option
                    selected={
                      editEmployee[0]?.category_id === category.id && "selected"
                    }
                    value={category.id}
                    key={category.id}
                  >
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="department" className="form-label mb-2">
                Employee department *
              </label>
              <select
                className="custom-select"
                id="department"
                name="department"
                aria-label="Default select example"
              >
                <option value="">Select department</option>
                {employDepartments?.map((dept) => (
                  <option
                    selected={
                      editEmployee[0]?.department_id === dept.id && "selected"
                    }
                    value={dept.id}
                    key={dept.id}
                  >
                    {dept.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12 d-flex align-items-center">
            <div className="form-group w-100">
              <label htmlFor="address" className="form-label mb-2">
                Address
              </label>
              <textarea
                name="address"
                id="address"
                rows="5"
                className="form-control"
                defaultValue={editEmployee[0]?.address || ""}
              ></textarea>
            </div>
          </div>
        </div>
        <button className="btn btn-success mb-5 mt-3" type="submit">
          Update Employee
        </button>
      </form>
    </>
  );
}
