import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ManageSales() {
  const [allReturn, setAllReturn] = useState([]);
  const [allSales, setAllSales] = useState([]);
  const [salesProducts, setSalesProducts] = useState([]);
  let requestMethods = new RequestMethods();
  const navigate = useNavigate();

  const getSalesReturnList = () => {
    requestMethods.getFunction("/sales/return-sales", setAllReturn);
  };

  function handleDelete(id) {
    let response = requestMethods.deleteFunction(
      `/sales/delete-return-sales/${id}`,
      getSalesReturnList
    );

    if (response) {
      toast.success("Return list successfully deleted!");
    } else {
      toast.error("Return list deleting failed!");
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const refEl = e.target.reference;
    const refTrimmed = refEl.value.trim();
    if (allSales.length) {
      //check if there is a reference in the database
      const findRef = allSales.find((sale) => sale.reference === refTrimmed);
      if (findRef) {
        document.getElementById("SaleRefCloseModal").click();
        navigate(`/sales/add-return/${refTrimmed}`);
      } else {
        toast.error("Please provide a valid sale reference!");
        refEl.focus();
      }
    }
  }

  function getEditReturnSaleProduct(id) {
    requestMethods.getFunction(`sales/sales-product/${id}`, setSalesProducts);
  }

  useEffect(() => {
    getSalesReturnList();
    requestMethods.getFunction("/sales", setAllSales);
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#saleReturn_modal"
        >
          <i className="fa-solid fa-plus" />
          Add Sale Return
        </button>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>

        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Date <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Reference <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Customer</th>
              <th scope="col">Warehouse</th>
              <th scope="col">Grand Total</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allReturn.length ? (
              allReturn.map((returnList) => (
                <tr key={returnList.id}>
                  <td>{returnList.date}</td>
                  <td>{returnList.reference}</td>
                  <td>{returnList.customer}</td>
                  <td>{returnList.warehouse}</td>
                  <td>{returnList.grand_total}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#saleReturnProduct_modal"
                            onClick={() =>
                              getEditReturnSaleProduct(returnList.reference)
                            }
                          >
                            <i class="fa-sharp fa-solid fa-eye mr-2"></i>
                            View
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleDelete(returnList.id)}
                          >
                            <i className="fa-solid fa-trash mr-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11} className="text-center">
                  No returnList added
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        id="saleReturnProduct_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog salesReturnProductModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Sales Return Products
              </h5>
              <button
                type="button"
                className="close"
                id="CloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table mt-3">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Code</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Net Unit Cost</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Tax</th>
                      <th scope="col">SubTotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesProducts?.map((product) => (
                      <tr>
                        <td>{product.name}</td>
                        <td>{product.code}</td>
                        <td style={{ maxWidth: "100px" }}>
                          <input
                            type="number"
                            className="form-control"
                            value={product.quantity}
                            disabled
                          />
                        </td>
                        <td>{product.price}</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>{product.price * product.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="saleReturn_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Sale Return
              </h5>
              <button
                type="button"
                className="close"
                id="SaleRefCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="reference" className="form-label">
                    Sale Reference *
                  </label>
                  <input
                    type="text"
                    name="reference"
                    className="form-control"
                    placeholder="please type correct sale reference..."
                    id="reference"
                  />
                </div>
                <button className="btn btn-success" type="submit">
                  Add return
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
