import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods, { checkNumber } from "../../helper";
import SaleOrderedProductRow from "./SaleOrderedProductRow";

export default function AddSales() {
  const navigage = useNavigate();
  const [selectedProductInput, setSelectedProductInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [allWarehouse, setAllWarehouse] = useState([]);
  const [allCustomer, setAllCustomer] = useState([]);
  const [file, setFile] = useState(null);
  const [vat, setVat] = useState(0);
  // const [shipping, setShipping] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [showPaying, setShowPaying] = useState("pending");

  let requestMethods = new RequestMethods();

  function getTotalQuantity() {
    let totalQuantity = 0;
    orderProducts.forEach(
      (data) => (totalQuantity = totalQuantity + data.productQuantity)
    );
    return totalQuantity;
  }
  function getSubtotal() {
    let subtotal = 0;
    orderProducts.forEach(
      (product) =>
        (subtotal = subtotal + product.productQuantity * product.price)
    );
    return subtotal;
  }

  const handleOrderProductDelete = (id) => {
    //delete ordered product;
    let filteredOrderedProduct = orderProducts.filter(
      (product) => product.id != id
    );
    setOrderProducts(filteredOrderedProduct);
  };

  const getSelectedProducts = (e) => {
    if (selectedProductInput) {
      let filteredProductWithInput = allProducts.filter((product) =>
        product.name
          .toLowerCase()
          .startsWith(selectedProductInput.toLowerCase())
      );
      setSelectedProducts(filteredProductWithInput);
    } else {
      setSelectedProducts([]);
    }
  };

  const selectProduct = (id) => {
    //check if the product is already there
    let productIsPresent = orderProducts.find((product) => product.id == id);
    if (productIsPresent) {
      setSelectedProducts([]);
      setSelectedProductInput("");
      return;
    }

    //push the product to the table
    let product = allProducts.find((product) => product.id == id);
    let productObj = {
      ...product,
      productQuantity: 1,
    };
    setOrderProducts((products) => [...products, productObj]);
    setSelectedProductInput("");
  };

  const calcTax = () => {
    const subTotal = getSubtotal();
    const vatValue = subTotal * (Number(vat) / 100);
    return vatValue.toFixed(2);
  };

  const getGrandTotal = () => {
    const discountValue = checkNumber(Number(discount));
    // const shippingValue = checkNumber(Number(shipping));
    return Number(getSubtotal()) + Number(calcTax()) - discountValue;
  };

  function checkForProductAvailableQty() {
    let productIsAvailable = true;
    for (let i = 0; i < orderProducts.length; i++) {
      if (orderProducts[i].quantity === 0) {
        alert(
          `${orderProducts[i].name} product is not available, purchase the product!`
        );
        productIsAvailable = false;
        break;
      }
    }

    return productIsAvailable;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!checkForProductAvailableQty()) {
      return;
    }

    const formData = new FormData(e.target);
    const date = new Date().toLocaleDateString();
    const ref = `sr-${Math.floor(Math.random() * 99999999 + 1)}`;
    formData.set("document", file);
    formData.append("date", date);
    formData.append("reference", ref);
    formData.append("grand_total", getSubtotal());
    formData.set("total_discount", discount);
    // formData.set("shipping_cost", checkNumber(Number(shipping)));

    const response = await requestMethods.postFunction(
      "/sales/add-sales",
      formData
    );
    if (response.success) {
      const salesProductData = {
        product_sales: orderProducts,
        refId: ref,
        customer_id: e.target.customer.value,
      };
      //send the sales product
      requestMethods.postFunction("/sales/product-sales", salesProductData);

      e.target.reset();
      setFile();
      navigage("/sales/manage-sales");
      toast.success("Sales added successfully!");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    getSelectedProducts();
  }, [selectedProductInput]);

  useEffect(() => {
    let productsApi = "product/product-list";
    requestMethods.getFunction(productsApi, setAllProducts);
    requestMethods.getFunction("/customer", setAllCustomer);
    requestMethods.getFunction("/warehouse", setAllWarehouse);
  }, []);

  return (
    <>
      <div className="p-4">
        <h4 className="mb-3 mt-3">Add Sale</h4>
        <div>
          <p className="mb-4 mt-3 italic">
            <small>
              The field labels marked with * are required input fields.
            </small>
          </p>
          <form id="purchase-form" onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="mb-2">
                  <label htmlFor="customer" className="form-label mb-2">
                    Customer *
                  </label>
                  <select
                    className="custom-select"
                    id="customer"
                    name="customer"
                  >
                    <option value="">Select customer...</option>
                    {allCustomer?.map((customer) => (
                      <option value={customer.id}>{customer.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-2">
                  <label htmlFor="warehouse" className="form-label mb-2">
                    Warehouse *
                  </label>
                  <select
                    className="custom-select"
                    id="warehouse"
                    name="warehouse"
                  >
                    <option value="">Select warehouse...</option>
                    {allWarehouse?.map((warehouse) => (
                      <option value={warehouse.id}>{warehouse.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3 mt-3">
                  <label htmlFor="selectProduct" className="form-label mb-2">
                    Select Product
                  </label>
                  <div className="input-group">
                    <button className="btn btn-secondary">
                      <i className="fa fa-barcode"></i>
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      id="selectProduct"
                      name="selectProduct"
                      value={selectedProductInput}
                      onChange={(e) => setSelectedProductInput(e.target.value)}
                      placeholder="please type product code and select"
                    />

                    {!selectedProducts?.length || (
                      <ul className="list-group position-absolute w-100 mt-5">
                        {selectedProducts.map((product) => (
                          <li
                            key={product.id}
                            className="list-group-item pointer"
                            onClick={() => selectProduct(product.id)}
                          >
                            {product.name} ( {product.code} )
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <h5 className="mt-3">Order Table *</h5>
            <div className="table-responsive">
              <table className="table mt-3">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Code</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Net Unit Price</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Tax</th>
                    <th scope="col">SubTotal</th>
                    <th scope="col">Available Quantity</th>
                    <th scope="col">MRP</th>
                    <th scope="col">Free Quantity</th>
                    <th scope="col">Free Available</th>
                    <th scope="col">
                      <i className="fa-regular fa-trash-can"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!orderProducts.length ? (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        No data available in table
                      </td>
                    </tr>
                  ) : (
                    orderProducts.map((product) => (
                      <SaleOrderedProductRow
                        key={product.id}
                        {...{
                          setOrderProducts,
                          orderProducts,
                          product,
                          handleOrderProductDelete,
                        }}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>

            <table className="table table-bordered mb-5  totals">
              <tbody>
                <tr>
                  <td>
                    <strong>Items</strong>{" "}
                    <span className="pull-right" id="item">
                      {orderProducts.length}({getTotalQuantity()})
                    </span>
                  </td>
                  <td>
                    <strong>Total</strong>
                    <span className="pull-right" id="subtotal">
                      ({getSubtotal()})
                    </span>
                  </td>
                  <td>
                    <strong>Order Tax</strong>
                    <span className="pull-right" id="order_tax">
                      {" "}
                      ({calcTax()})
                    </span>
                  </td>
                  <td>
                    <strong>Order Discount</strong>
                    <span className="pull-right" id="order_discount">
                      {" "}
                      ({discount || 0})
                    </span>
                  </td>
                  {/* <td>
                    <strong>Shipping Cost</strong>
                    <span className="pull-right" id="shipping_cost">
                      {" "}
                      ({shipping || 0})
                    </span>
                  </td> */}
                  <td>
                    <strong>Grand Total</strong>
                    <span className="pull-right" id="grand_total">
                      {" "}
                      {getGrandTotal() || 0}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row">
              <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="document">
                    Attach Document{" "}
                    <i
                      className="fa-regular fa-circle-question"
                      data-toggle="tooltip"
                      title="Only png, jpeg, pdf, txt file is supported!"
                    ></i>
                  </label>
                  <input
                    type="file"
                    name="document"
                    id="document"
                    className="form-control"
                    onChange={(e) => setFile(e.target.files[0])}
                    accept="image/png, image/jpeg, application/pdf, application/txt"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="orderTax" className="form-label mb-2">
                    Order Tax
                  </label>
                  <select
                    className="custom-select"
                    id="orderTax"
                    aria-label="Default select example"
                    onChange={(e) => setVat(e.target.value)}
                  >
                    <option value={0}>No Tax</option>
                    <option value={10}>vat@10</option>
                    <option value={15}>vat@15</option>
                    <option value={20}>vat 20</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="discount" className="form-label mb-2">
                    Discount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="discount"
                    name="discount"
                    onChange={(e) => setDiscount(e.target.valueAsNumber)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2">
                  <label
                    htmlFor="invoice_payment_status"
                    className="form-label mb-2"
                  >
                    Invoice Payment Status
                  </label>
                  <select
                    className="custom-select"
                    id="invoice_payment_status"
                    name="invoice_payment_status"
                    aria-label="Default select example"
                    onChange={(e) => setShowPaying(e.target.value)}
                  >
                    <option value="pending">Pending</option>
                    <option value="due">Due</option>
                    <option value="partial">Partial</option>
                    <option value="paid">Paid</option>
                  </select>
                </div>
              </div>
              {/* <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="shipping_cost" className="form-label mb-2">
                    Shipping
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="shipping_cost"
                    name="shipping_cost"
                    min={0}
                    onChange={(e) => setShipping(e.target.valueAsNumber)}
                  />
                </div>
              </div> */}
            </div>

            {showPaying !== "pending" && showPaying !== "due" ? (
              <div className="row mt-2 mb-2">
                <div className="col-sm-4">
                  <div className="mb-2">
                    <label htmlFor="paying_method" className="form-label mb-2">
                      Paid By
                    </label>
                    <select
                      className="custom-select"
                      id="paying_method"
                      name="paying_method"
                      aria-label="Default select example"
                    >
                      <option value="cash">Cash</option>
                      <option value="gift card">Gift Card</option>
                      <option value="creadit card">Credit Card</option>
                      <option value="Cheque">Cheque</option>
                      <option value="paypal">Paypal</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="mb-2">
                    <label htmlFor="paying_amount" className="form-label mb-2">
                      Paying Amount
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="paying_amount"
                      name="paying_amount"
                      min={0}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="row">
              <div className="col-sm-6">
                <div className="mb-2">
                  <label htmlFor="saleNote" className="form-label mb-2">
                    Sale Note
                  </label>
                  <textarea
                    className="form-control"
                    id="saleNote"
                    name="saleNote"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-2">
                  <label htmlFor="staffNote" className="form-label mb-2">
                    Staff Note
                  </label>
                  <textarea
                    className="form-control"
                    id="staffNote"
                    name="staffNote"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            </div>

            <button className="btn btn-success mt-3" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
