import React, { useEffect} from "react";

export default function ReturnOrder() {
  useEffect(() => {
    document.title = 'Return Order';  
  }, []);

  return (
    <>
      <h1>Return Order</h1>
    </>
  );
}