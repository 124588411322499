import React, { useEffect} from "react";

export default function TransferredList() {
  useEffect(() => {
    document.title = 'Transferred List';  
  }, []);

  return (
    <>
      <h1>Transferred List</h1>
    </>
  );
}