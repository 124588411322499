import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../helper";

export default function useSales(api) {
  const [allSales, setAllSales] = useState([]);
  let requestMethods = new RequestMethods();

  const getSalesList = () => {
    requestMethods.getFunction(api, setAllSales);
  };

  function handleDelete(ref) {
    let response = requestMethods.deleteFunction(
      `/sales/delete-sales/${ref}`,
      getSalesList
    );

    if (response) {
      toast.success("Sales successfully deleted!");
    } else {
      toast.error("Sales deleting failed!");
    }
  }

  function getGrandTotal() {
    const sum = allSales.reduce((total, sale) => total + sale.grand_total, 0);
    return sum;
  }

  function getTotalPaid() {
    const sum = allSales.reduce((total, sale) => total + sale.paid, 0);
    return sum;
  }

  useEffect(() => {
    getSalesList();
  }, []);

  return { allSales, handleDelete, getGrandTotal, getTotalPaid };
}
