import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function ProductChildCategory() {
  const [subcategories, setSubcategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [editChildCategorie, setEditChildCategorie] = useState([]);
  const requestMethods = new RequestMethods();

  const getSubcategories = async () => {
    const api = "/product/product-subcategory";
    requestMethods.getFunction(api, setSubcategories);
  };

  const handleDeleteChildCategory = async (id) => {
    const api = `/product/delete-childcategory/${id}`;
    requestMethods.deleteFunction(api, getChildCategoriData);
  };

  const getChildCategoriData = (id) => {
    if (id) {
      const api = `/product/product-childcategory/${id}`;
      requestMethods.getFunction(api, setEditChildCategorie);
    } else {
      const api = "/product/product-childcategory";
      requestMethods.getFunction(api, setChildCategories);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = document.querySelector("#childCategoryForm");
    const formData = new FormData(form);

    if (editChildCategorie.length) {
      const api = `/product/edit-childcategory/${editChildCategorie[0].id}`;
      const response = await requestMethods.patchFunction(api, formData);
      if (response) {
        form.reset();
        document.querySelector(".close").click();
        getChildCategoriData();
        toast.success("Child Category updated successfully!");
      } else {
        toast.error("Child Category updating failed!");
      }
    } else {
      const api = "/product/add-childcategory";
      const response = await requestMethods.postFunction(api, formData);
      if (response.success) {
        form.reset();
        document.querySelector(".close").click();
        getChildCategoriData();
        toast.success("Child Category added successfully!");
      } else {
        response.error.forEach((err) => {
          toast.error(err.message);
          e.target[err.field].focus();
        });
      }
    }
  };

  useEffect(() => {
    getChildCategoriData();
    getSubcategories();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#add_childcategory"
          onClick={() => setEditChildCategorie([])}
        >
          <i className="fa-solid fa-plus" />
          Add Child Category
        </button>
      </div>
      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Child Category <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Subcategory <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {childCategories.map((childcategory) => (
              <tr>
                <td>{childcategory.name}</td>
                <td>{childcategory.subcategory_id}</td>
                <td>
                  <div className="dropdown dropstart">
                    <button
                      className="btn btn-warning dropdown-toggle btn-sm"
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenu2"
                    >
                      <li>
                        <button
                          onClick={() => getChildCategoriData(childcategory.id)}
                          className="dropdown-item"
                          type="button"
                          data-toggle="modal"
                          data-target="#add_childcategory"
                        >
                          <i className="fa-solid fa-pen-to-square me-2" />
                          Edit
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() =>
                            handleDeleteChildCategory(childcategory.id)
                          }
                        >
                          <i className="fa-solid fa-trash me-2" />
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="add_childcategory"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {editChildCategorie.length
                  ? "Edit Child Category"
                  : "Add Child Category"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} id="childCategoryForm">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    id="name"
                    defaultValue={editChildCategorie[0]?.name}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="warehouse" className="form-label">
                    Subcategory
                  </label>
                  <select
                    className="custom-select"
                    aria-label="Default select example"
                    id="subCategory"
                    name="subCategory"
                  >
                    {subcategories.map((subcategory) => (
                      <option
                        selected={
                          editChildCategorie[0]?.subcategory == subcategory.id
                            ? "selected"
                            : ""
                        }
                        value={subcategory.id}
                      >
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                </div>
                <button className="btn btn-success" type="submit">
                  {editChildCategorie.length ? "Update" : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
