import React, { useEffect} from "react";

export default function PayRoll() {
  useEffect(() => {
    document.title = 'Pay Roll';  
  }, []);

  return (
    <>
      <h1>Pay Roll</h1>
    </>
  );
}