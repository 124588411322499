import React, { useEffect} from "react";

export default function SoldProductReport() {
  useEffect(() => {
    document.title = 'Sold Product Report';  
  }, []);

  return (
    <>
      <h1>Sold Product Report</h1>
    </>
  );
}