import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";
import TransferRow from "./TransferRow";

export default function AddTransfer() {
  const navigate = useNavigate();
  const [selectedProductInput, setSelectedProductInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [orderProducts, setOrderProducts] = useState([]);
  const [orderProductMetaData, setOrderProductMetaData] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allWarehouse, setAllWarehouse] = useState([]);
  const [file, setFile] = useState(null);
  // const [shipping, setShipping] = useState(0);
  let requestMethods = new RequestMethods();

  function getTotalQuantity() {
    let totalQuantity = 0;
    orderProductMetaData.forEach(
      (metadata) => (totalQuantity = totalQuantity + metadata.productQuantity)
    );
    return totalQuantity;
  }
  function getSubtotal() {
    let subtotal = 0;
    orderProductMetaData.forEach(
      (metadata) => (subtotal = subtotal + metadata.productTotal)
    );
    return subtotal;
  }

  const getGrandTotal = () => {
    return Number(getSubtotal());
  };

  const handleOrderProductDelete = (id) => {
    let filteredProduct = orderProducts.filter((product) => product.id != id);
    setOrderProducts(filteredProduct);

    let filterProductMetaData = orderProductMetaData.filter(
      (product) => product.id != id
    );
    setOrderProductMetaData(filterProductMetaData);
  };

  const getSelectedProducts = (e) => {
    if (selectedProductInput) {
      let filteredProductWithInput = allProducts.filter((product) =>
        product.name
          .toLowerCase()
          .startsWith(selectedProductInput.toLowerCase())
      );
      setSelectedProducts(filteredProductWithInput);
    } else {
      setSelectedProducts([]);
    }
  };

  const selectProduct = (id) => {
    //check if the product is already there
    let productIsPresent = orderProducts.find((product) => product.id == id);
    if (productIsPresent) {
      return;
    }

    //push the product to the table
    let product = allProducts.find((product) => product.id == id);
    setOrderProducts((products) => [...products, product]);
    setSelectedProductInput("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (e.target.from_warehouse.value === e.target.to_warehouse.value) {
      alert("Both warehoue cannot be same!");
      return;
    }
    const formData = new FormData(e.target);
    formData.append("file", file);
    formData.append("product_cost", getSubtotal());
    formData.append("grand_total", getGrandTotal());
    const date = new Date().toLocaleString();
    const reference =
      "TRF - " + Math.floor(10000000 + Math.random() * 90000000);
    formData.append("date", date);
    formData.append("reference", reference);
    formData.append("transfer_products", JSON.stringify(orderProductMetaData));

    const api = "/transfer/add-transfer";
    const response = await requestMethods.postFunction(api, formData);
    if (response.success) {
      toast.success("Transfer added successfully");
      e.target.reset();
      setFile(null);
      navigate("/transfer/transfer-list");
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  useEffect(() => {
    getSelectedProducts();
  }, [selectedProductInput]);

  useEffect(() => {
    let productsApi = "product/product-list";
    requestMethods.getFunction(productsApi, setAllProducts);
    let warehouseAPi = "/warehouse";
    requestMethods.getFunction(warehouseAPi, setAllWarehouse);
  }, []);

  return (
    <>
      <div className="p-4">
        <h4 className="mb-3 mt-3">Add Transfer</h4>
        <div>
          <p className="mb-4 mt-3 italic">
            <small>
              The field labels marked with * are required input fields.
            </small>
          </p>
          <form id="purchase-form" onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="from_warehouse" className="form-label mb-2">
                    From Warehouse *
                  </label>
                  <select
                    className="custom-select"
                    id="from_warehouse"
                    name="from_warehouse"
                  >
                    <option value="">Select wareshouse...</option>
                    {allWarehouse.map((warehouse) => (
                      <option key={warehouse.id} value={warehouse.id}>
                        {warehouse.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="to_warehouse" className="form-label mb-2">
                    To Warehouse *
                  </label>
                  <select
                    className="custom-select"
                    id="to_warehouse"
                    name="to_warehouse"
                  >
                    <option value="">Select wareshouse...</option>
                    {allWarehouse.map((warehouse) => (
                      <option key={warehouse.id} value={warehouse.id}>
                        {warehouse.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="status" className="form-label mb-2">
                    Status
                  </label>
                  <select className="custom-select" id="status" name="status">
                    <option value="completed">completed</option>
                    <option value="pending">pending</option>
                    <option value="sent">sent</option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-2 mt-4">
                  <label htmlFor="selectProduct" className="form-label mb-2">
                    Select Product
                  </label>
                  <div className="input-group">
                    <button className="btn btn-secondary">
                      <i className="fa fa-barcode"></i>
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      id="selectProduct"
                      name="selectProduct"
                      value={selectedProductInput}
                      onChange={(e) => setSelectedProductInput(e.target.value)}
                      placeholder="please type product code and select"
                    />

                    {!selectedProducts?.length || (
                      <ul className="list-group position-absolute w-100 mt-5">
                        {selectedProducts.map((product) => (
                          <li
                            key={product.id}
                            className="list-group-item pointer"
                            onClick={() => selectProduct(product.id)}
                          >
                            {product.name} ( {product.code} )
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <h5 className="fs-5 mt-5 mb-3 text-left">Order table *</h5>
            <div className="table-responsive">
              <table className="table mb-3">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Code</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Net Unit Cost</th>
                    <th scope="col">Tax</th>
                    <th scope="col">SubTotal</th>
                    <th scope="col">
                      <i className="fa-regular fa-trash-can"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!orderProducts.length ? (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        No data available in table
                      </td>
                    </tr>
                  ) : (
                    orderProducts.map((product) => (
                      <TransferRow
                        {...{
                          setOrderProductMetaData,
                          orderProductMetaData,
                          product,
                          handleOrderProductDelete,
                        }}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>

            <table className="table table-bordered mb-5  totals">
              <tbody>
                <tr>
                  <td>
                    <strong>Items</strong>
                    <span className="pull-right float-right" id="item">
                      {orderProducts.length}({getTotalQuantity()})
                    </span>
                  </td>
                  <td>
                    <strong>Total</strong>
                    <span className="pull-right float-right" id="subtotal">
                      ({getSubtotal()})
                    </span>
                  </td>
                  {/* <td>
                    <strong>Shipping Cost</strong>
                    <span className="pull-right float-right" id="shipping_cost">
                      ({shipping || 0})
                    </span>
                  </td> */}
                  <td>
                    <strong>Grand Total</strong>
                    <span className="pull-right float-right" id="grand_total">
                      {getGrandTotal() || 0}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="row mt-3">
              {/* <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="shippingCost" className="form-label mb-2">
                    Shipping Cost
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="shippingCost"
                    name="shippingCost"
                    min={0}
                    value={shipping}
                    onChange={(e) => setShipping(e.target.valueAsNumber)}
                    required
                  />
                </div>
              </div> */}
              <div className="col-sm-4">
                <div className="mb-2">
                  <label htmlFor="file">
                    Attach Document{" "}
                    <i
                      className="fa-regular fa-circle-question"
                      data-toggle="tooltip"
                      title="Only png, jpeg, pdf, txt file is supported!"
                    ></i>
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="form-control"
                    onChange={(e) => setFile(e.target.files[0])}
                    accept="image/png, image/jpeg, application/pdf, application/txt"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="mb-2">
                  <label htmlFor="note" className="form-label mb-2">
                    Note
                  </label>
                  <textarea
                    className="form-control"
                    id="note"
                    name="note"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            </div>

            <button className="btn btn-success mt-3" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
