import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import RequestMethods from "../../helper";

export default function AssetCategory() {
  const [categories, setCategories] = useState([]);
  const requestMethods = new RequestMethods();

  const submitHandler = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    const categoryCloseModal = document.getElementById("categoryCloseModal");

    const api = "/asset/add-category";
    const response = await requestMethods.postFunction(api, formData);
    if (response.success) {
      toast.success("Asset Category added successfully");
      categoryCloseModal.click();
      e.target.reset();
      getAllCategories();
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
      });
      e.target[response.error[0]?.field].focus();
    }
  };

  const handleDelete = (id) => {
    const api = `/asset/delete-category/${id}`;
    const response = requestMethods.deleteFunction(api, getAllCategories);
    if (response) {
      toast.success("Asset Category deleted successfully");
    } else {
      toast.error("Asset Category delete failed!");
    }
  };

  const getAllCategories = () => {
    const api = "/asset/category";
    requestMethods.getFunction(api, setCategories);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#category_modal"
        >
          <i className="fa-solid fa-plus" />
          Add Asset Category
        </button>
      </div>
      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">
                Name <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">
                Number of Assets <i className="fa-solid fa-sort" />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {categories.length ? (
              categories.map((cat) => (
                <tr key={cat.id}>
                  <td>{cat.name}</td>
                  <td>{cat.number_of_assets}</td>
                  <td>
                    <div className="dropdown dropstart">
                      <button
                        className="btn btn-warning dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Action
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            onClick={() => handleDelete(cat.id)}
                            className="dropdown-item"
                            type="button"
                          >
                            <i className="fa-solid fa-trash me-2" />
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="100%" className="text-center">
                  No asset category added
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="category_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Asset Category
              </h5>
              <button
                type="button"
                className="close"
                id="categoryCloseModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={submitHandler} id="employeeDepartmentForm">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name *
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                  />
                </div>
                <button className="btn btn-success" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
