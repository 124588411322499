import React, { useEffect } from "react";
import RequestMethods from "../../helper";
import { toast } from "react-toastify";
import { useState } from "react";

export default function ProductColor() {
  const [colors, setColors] = useState([]);
  let requestMethods = new RequestMethods();

  async function handleSubmit(e) {
    e.preventDefault();

    let form = document.getElementById("addColorForm");
    let formData = new FormData(form);

    const api = "/product/add-color";
    let response = await requestMethods.postFunction(api, formData);

    if (response.success) {
      form.reset();
      toast.success("Color added");
      getProductColors();
      document.querySelector("#add_color .close").click();
    } else {
      response.error.forEach((err) => {
        toast.error(err.message);
        e.target[err.field].focus();
      });
    }
  }

  function handleDeleteColor(id) {
    let api = `/product/delete-color/${id}`;
    const response = requestMethods.deleteFunction(api, getProductColors);
    if (response) {
      toast.success("Color deleted!");
    } else {
      toast.error("Color delete failed!");
    }
  }

  async function getProductColors() {
    let api = "product/color";
    requestMethods.getFunction(api, setColors);
  }

  useEffect(() => {
    getProductColors();
  }, []);

  return (
    <>
      <div className="add__button">
        <button
          type="button"
          className="btn"
          data-toggle="modal"
          data-target="#add_color"
        >
          <i className="fa-solid fa-plus" />
          Add Color
        </button>
      </div>

      <div className="row mt-3 flex-column-reverse flex-md-row gy-3">
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="custom-select form-select-sm"
            aria-label=".form-select-sm example"
          >
            <option value={10} selected="">
              10
            </option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value="all">all</option>
          </select>
          <span>records per page</span>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-end">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <label htmlFor="search" className="col-form-label">
                Search
              </label>
            </div>
            <div className="col-auto">
              <input type="text" id="search" className="form-control" />
            </div>
          </div>
          <div className="file__icons">
            <i className="fa-solid fa-file-pdf" />
            <i className="fa-solid fa-table" />
            <i className="fa-solid fa-print" />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">Code</th>
              <th scope="col">Color</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!colors.length ? (
              <tr>
                <td colspan="2" className="text-center">
                  no data
                </td>
              </tr>
            ) : (
              colors.map((color) => (
                <tr key={color.id}>
                  <td>{color.code}</td>
                  <td>
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        background: `${color.code}`,
                      }}
                    ></div>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger btn-sm"
                      type="button"
                      onClick={() => handleDeleteColor(color.id)}
                    >
                      <i className="fa-solid fa-trash me-2" />
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>Showing 0 to 0 of 0 entries</p>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="modal fade"
        id="add_color"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Color
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} id="addColorForm">
                <div className="mb-3">
                  <label htmlFor="color" className="form-label">
                    Color
                  </label>
                  <input
                    type="color"
                    className="form-control"
                    style={{ height: "100px" }}
                    id="color"
                    name="code"
                  />
                </div>
                <button className="btn btn-success" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
