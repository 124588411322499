import React, { useEffect} from "react";

export default function AllUser() {
  useEffect(() => {
    document.title = 'All User';  
  }, []);

  return (
    <>
      <h1>All User</h1>
    </>
  );
}